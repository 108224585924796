import React, { useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { IonContent, IonList, IonItem, IonLabel, IonButton, IonSpinner } from '@ionic/react';
import { colors } from 'kards-components';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';
import { ReactComponent as About } from 'src/svg/about.svg';
import { ReactComponent as Help } from 'src/svg/help.svg';
import { ReactComponent as Order } from 'src/svg/order.svg';
import { ReactComponent as Profile } from 'src/svg/profile.svg';
import { ReactComponent as Contact } from 'src/svg/contact.svg';
import { ReactComponent as Arrow } from 'src/svg/arrow.svg';


export default function Menu() {
  const theme = useTheme();
  const history = useHistory();
  const titleColor = colors[get(theme, 'color.title')]
  const border = `solid 1px ${titleColor}`

  return (
    <IonContent color={get(theme, 'color.background')}>
      <IonList style={{ padding: '1em 0', background: colors[get(theme, 'color.background')] }}>
        <Item onClick={() => history.push('/menu/history')} lines="inset" color={get(theme, 'color.background')}>
          <RowWrapper
            data-cy="order-list"
            color={titleColor}
            borderTop={border}
          >
            <Box flex=".3">
              <OrderSvg color={titleColor} />
              <IonLabel>Mes commandes</IonLabel>
            </Box>
            <ArrowSvg color={titleColor} />
          </RowWrapper>
        </Item>
        <Item onClick={() => history.push('/menu/profile')} color={get(theme, 'color.background')}>
          <RowWrapper
            color={titleColor}
            borderTop={border}
          >
            <Box flex=".3">
              <ProfileSvg color={titleColor} />
              <IonLabel>Mon profil</IonLabel>
            </Box>
            <ArrowSvg color={titleColor} />
          </RowWrapper>
        </Item>
        <Item onClick={() => history.push('/menu/help')} color={get(theme, 'color.background')}>
          <RowWrapper color={titleColor} borderTop={border}>
            <Box flex=".3">
              <HelpSvg color={titleColor} />
              <IonLabel>{"Besoin d'aide ?"}</IonLabel>
            </Box>
            <ArrowSvg color={titleColor} />
          </RowWrapper>
        </Item>
        <Item onClick={() => history.push('/menu/contacts')} color={get(theme, 'color.background')}>
          <RowWrapper color={titleColor} borderTop={border} rTop>
            <Box flex=".3">
              <ContactSvg color={titleColor} />
              <IonLabel>Nous contacter</IonLabel>
            </Box>
            <ArrowSvg color={titleColor} />
          </RowWrapper>
        </Item>
        <Item onClick={() => history.push('/menu/about')} color={get(theme, 'color.background')}>
          <RowWrapper
            color={titleColor}
            borderTop={border}
            borderBottom={border}
          >
            <Box flex=".3">
              <AboutSvg color={titleColor} />
              <IonLabel>À propos</IonLabel>
            </Box>
            <ArrowSvg color={titleColor} />
          </RowWrapper>
        </Item>
        <SignOutButton />
      </IonList>
    </IonContent>
  );
}

function SignOutButton() {
  const { user }: any = useReadContext();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const signOut = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          setLoading(false);
        })
        .catch(e => {
          setError(e);
          console.log('Log: signOut -> error', error);
          // An error happened.
        });
    }, 2000);
  };

  return (
    <>
      {user ? (
        <IonButton
          color={get(theme, 'color.title')}
          size="small"
          style={{
            margin: '4vh',
            fontSize: '0.8em',
            '--border-radius': '30px',
          }}
          onClick={signOut}
        >
          {loading && <IonSpinner style={{ color: colors[get(theme, 'color.title')] }} name="lines-small" />}
          Deconnecter mon numéro
        </IonButton>
      ) : (
        <IonButton
          color={get(theme, 'color.title')}
          size="small"
          style={{
            margin: '4vh',
            fontSize: '0.8em',
            '--border-radius': '30px',
          }}
          onClick={() => history.push('/auth')}
        >
          Connecter mon numéro
        </IonButton>
      )}
    </>
  );
}

const Item = styled(IonItem)`
  --inner-border-width: 0;
  --border-width: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${props => props.borderBottom};
  margin-top: ${props => props.marginTop};
  border-top: ${props => props.borderTop};
  --inner-border-width: 0;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: ${props => props.flex};
`;

const ArrowSvg = styled(Arrow)`
  width: 0.6em;
  margin: 0 1em;
  pa .st0 {
    fill: ${props => props.color} !important;
  }
`;

const OrderSvg = styled(Order)`
  width: 1.5em;
  margin: 0 1em;
  .st0 {
    fill: ${props => props.color} !important;
  }
`;

const ProfileSvg = styled(Profile).attrs(({color}) => ({
  stroke: color,
}))`
  width: 1.5em;
  margin: 0 1em;
`;

const HelpSvg = styled(Help)`
  width: 1.5em;
  margin: 0 1em;
  .st0 {
    fill: ${props => props.color} !important;
  }
`;

const ContactSvg = styled(Contact)`
  width: 1.5em;
  margin: 0 1em;
  .st0 {
    fill: ${props => props.color} !important;
  }
`;

const AboutSvg = styled(About)`
  width: 1.5em;
  margin: 0 1em;
  .st0 {
    fill: ${props => props.color} !important;
  }
`;
