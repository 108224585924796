import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { orderPrepared } from '../accessor/api';

export const useTimeOrder = ({ initialTime, averageTime }) => {
  const [time, setTime] = useState(averageTime);
  useEffect(() => {
    orderPrepared().then((orderDeliveredData: any) => {
      const count = filter(orderDeliveredData, o => o.created_at < initialTime);
      if (count.length > 0) {
        const average: any = Number(averageTime);
        const time = average * count.length;
        setTime(time);
      }
    });
  }, []);

  return { data: time };
};
