import React, { useEffect, useRef } from 'react';
import { eventNames } from './eventNames';
import { useAnalyticsContext } from './AnalyticsContext';

	
declare global {
  interface Window {
    visualViewport: HTMLElement;
  }
}

export const Viewed = ({ children, component }) => {
  const spanRef = useRef()
  const { logEvent } = useAnalyticsContext()
  useEffect(() => {
    const content = document.getElementsByTagName('ion-content')[0]
    let obs;
    if (content) {
      obs = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio === 1) {
            obs.unobserve(spanRef.current)
            
            logEvent({
              type: eventNames.componentViewed,
              componentId: component.id,
            })
          }
        })
      }, {
        threshold: 1,
        root: content,
      })
    }

    setTimeout(() => {
      if (spanRef?.current) obs && obs.observe(spanRef.current)
    }, 500)
  }, [])

  return (
    <>
      {children}
      <span ref={spanRef}></span>
    </>
  )
}