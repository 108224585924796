import React from 'react';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { colors } from 'kards-components';
import { IonLabel, IonRadioGroup, IonRadio } from '@ionic/react';
import { useTheme } from 'src/modules/card/context/theme';

const DARK = '#222428';
const WHITE = 'white';

const other = {
  tips: [
    {
      label: '2€',
      value: 200,
    },
    {
      label: '5€',
      value: 500,
    },
    {
      label: '10€',
      value: 1000,
    },
  ],
};

export default function Tips({ setTips, tips, darkTheme }) {
  const theme = useTheme();
  return (
    <>
      <IonRadioGroup
        onIonChange={e => setTips(e.detail.value)}
        allowEmptySelection
        data-cy="tips"
        color={get(theme, 'color.background')}
        name="tips"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          height: '10vh',
        }}
      >
        {map(other.tips, ({ label, value }) => (
          <IonItemWrapperTips data-cy={`${value}-key`} key={value} color={get(theme, 'color.background')}>
            <IonLabelWrapper color={get(theme, 'color.title')}>
              <Text darkTheme={darkTheme} fontWeigth="bold" textAlign="center" fontSize=".9em">
                {label}
              </Text>
            </IonLabelWrapper>
            <IonRadioWrapper
              style={{
                '--color': darkTheme ? WHITE : DARK,
                '--color-checked': '#5BFCC2',
                width: '2em',
                height: '2em',
              }}
              mode="md"
              checked={value === tips}
              value={value}
              slot="end"
              color={get(theme, 'color.title')}
            />
          </IonItemWrapperTips>
        ))}
      </IonRadioGroup>
    </>
  );
}

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => (props.darkTheme ? WHITE : DARK)};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeigth};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IonRadioWrapper = styled(IonRadio)`
  margin: 0.5em !important;
`;

const IonItemWrapperTips = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => colors[props.color]} !important;
`;

const IonLabelWrapper = styled(IonLabel)`
  align-items: center;
  color: ${props => colors[props.color]};
  display: flex !important;
  flex-direction: row;
`;
