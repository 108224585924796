import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';
import { IonSelect, IonSelectOption } from '@ionic/react';
import moment from 'moment';
import { colors } from 'kards-components';
import { useTheme } from 'src/modules/card/context/theme';
import { useReadContext } from 'src/modules/card/context';

function recursiveSlotTakeAway({ start, stop, timeOrder, array, time }) {
  const startCircle = moment(start, 'HH:mm:ss A');
  const end = moment(start, 'HH:mm:ss A').add(time, 'minute');
  array.push({
    label: `${startCircle.format('HH:mm')}-${end.format('HH:mm')}`,
    value: `${startCircle.format('HH:mm')}-${end.format('HH:mm')}`,
  });

  if (end < stop) {
    recursiveSlotTakeAway({
      start: end,
      stop,
      timeOrder,
      array,
      time,
    });
  }
}

export const SelectTakeAway = ({ onChange, name, value, dataAverageTime }) => {
  const [optionsState, setOptions] = useState([{}]);
  const theme = useTheme();
  const { open, day, service } = useReadContext();

  useEffect(() => {
    let time = 30;
    const initialMap = [];
    if (open && open[day.value] && open[day.value][service.value]) {
      const end = moment(open[day.value][service.value].end, 'HH:mm:ss A');
      let { start } = open[day.value][service.value];
      if (day.key === new Date().getDay()) {
        time = dataAverageTime < 0 ? dataAverageTime : 15;
        start = moment(new Date(), 'HH:mm:ss A').add(time, 'minutes');
      }
      recursiveSlotTakeAway({
        start,
        stop: end,
        array: initialMap,
        time,
      });
    }
    setOptions(initialMap);
  }, []);

  return (
    <div style={{ width: '100%', margin: '10px' }}>
      {optionsState.length > 0 ? (
        <>
          <IonSelect
            data-cy="preorder-select"
            placeholder="Choisissez un créneaux"
            okText="Choisir"
            cancelText="Annuler"
            name={name}
            value={value}
            onIonChange={onChange}
            bgcolor={colors[get(theme, 'color.background')]}
            color={colors[get(theme, 'color.title')]}
          >
            {map(optionsState, (op, i) => (
              <IonSelectOption data-cy={`preorder-select-${i}`} key={op.label} value={op.value}>
                {op.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </>
      ) : (
        'Aucun creneau disponible'
      )}
    </div>
  );
};
