import React from 'react';
import { getComponents } from './getComponents';
import { Viewed } from 'src/analytics/Viewed';

function Component({ component }) {
  const Component: any = getComponents(component.type);
  return <Component component={component} />;
}

const Item = ({ item, index }) => (
  <div style={{ width: '100%' }} data-cy={`btn-component-${index}`}>
    <Viewed component={item}>
      <Component component={item} />
    </Viewed>
  </div>
);

export default Item;
