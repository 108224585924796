import React, { useContext } from 'react';
import { WheelComponent } from 'kards-components';

import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ component }) => {
  const data = component.value
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <WheelComponent
      theme={theme}
      data={data}
    />
  );
};
