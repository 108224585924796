import React from 'react';
import { find, map, get } from 'lodash';
import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { colors } from 'kards-components';
import { useReadContext } from '../context';
import { useTheme } from '../context/theme';
import Item from './item';
import LastOrders from './LastOrders';
import Recommand from './Recommand';

export const ComponentsRead = () => {
  const { cardData, currentCategory }: any = useReadContext();
  const theme = useTheme();
  const category = find(cardData, c => c.id === currentCategory);
  const components = get(category, 'components');

  return (
    <>
      <IonContent data-cy="component-list" fullscreen color={get(theme, 'color.background')}>
        <List color={get(theme, 'color.background')}>
          {map(components, (item, i) => (
            <Item item={item} key={item.id} index={i} />
          ))}
        </List>
      </IonContent>
    </>
  );
};

const List = styled.div`
  background-color: ${props => colors[props.color]};
  padding-bottom: 30vh;
`;
