import React from 'react';
import { Carousel } from 'kards-components';
import useMedia from 'use-media';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { useLast5Products } from 'src/data/accessor/getLastOrders';

export default function LastOrders({ data }: any) {
  const theme = useTheme();
  const isWide = useMedia({ minWidth: '500px' });
  const products = useLast5Products();
  const title = data?.title || 'Dernières commandes 🔥';

  if (!products?.length) return null;

  return (
    <Carousel
      read
      theme={theme}
      data={{ products, title, description: data?.description ?? '' }}
      width={isWide ? '20vw' : '70vw'}
    >
      <Wrapper>
        {({ id }) => (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <AddRemoveBasket id={id} />
            <div>🔥</div>
          </div>
        )}
      </Wrapper>
    </Carousel>
  );
}

function Wrapper({ children, ...props }: any) {
  return <>{children(props)}</>;
}
