import React, { createContext, useContext, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useLocalStorage } from 'src/data/hooks/useLocalStorage';
import { useReadContext } from 'src/modules/card/context';
import { getTotalPrice, getNbProducts, isOffertDisponible, getProducts, productToBasket } from './utils';
import { Basket, Product } from 'src/data/types';
import { getStateUpdate } from 'src/helpers/getStateUpdate';
import { ReactStateUpdate } from 'src/helpers/TStypes';

type ContextType = {
  baskets: Basket[];
  amount: number;
  totalPrice: string;
  nbProducts: number;
  isOffertDisponible: (basket: Product) => boolean;
  addToBaskets: (id: string, basket: Partial<Basket>) => void;
  getProduct: (id: string) => Product;
  getBasketById: (id: string) => Basket;
  addProductToBasket: (i: { id: string; quantity: number }) => void;
  updateBaskets: (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => void;
  updateBasketsByOrderProductId: (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => void;
  resetBasket: () => void;
  setBaskets: (baskets: Basket[]) => void;
};

const BasketContext = createContext<ContextType>({} as ContextType);

export function useBasketContext() {
  return useContext(BasketContext);
}

function BasketProviderComponent({ children }) {
  const { cardData }: any = useReadContext();

  const products = useMemo(() => getProducts(cardData), [cardData]);

  const [baskets, setBasketsRaw] = useLocalStorage<Basket[]>('baskets', (basketFromLocalStorage: Basket[]) => basketFromLocalStorage || []);

  const setBaskets = (value: ReactStateUpdate<Basket[]>) =>
    setBasketsRaw(
      getStateUpdate<Basket[]>({
        state: baskets,
        update: value,
      }).filter(i => i.quantity > 0),
    );

  const getProduct = (id: string) => products.find(b => b.id === id);
  const getBasketById = (id: string) => baskets.find(b => b.id === id);
  const pickedBaskets = baskets.filter(b => b.quantity > 0);

  const totalPrice = getTotalPrice(pickedBaskets);
  const nbProducts = getNbProducts(pickedBaskets);

  const addToBaskets = (id: string, basket: Partial<Basket>) => {
    const basketFromProduct = productToBasket(getProduct(id)) || {};

    setBaskets(bs => [...bs, { ...basketFromProduct, ...basket, orderProductId: uuid() }] as Basket[]);
  };

  const updateBaskets = (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => {
    if (baskets.find(b => b.id === id)) {
      setBaskets(bs =>
        bs.map(basket => {
          if (basket.id === id) return { ...basket, ...getBasketToUpdate(basket) };
          return basket;
        }),
      );
    } else {
      addToBaskets(id, { quantity: 1 });
    }
  };

  // not meant to be exposed, too powerful
  const upsertItem = ({ id, patch }: { id: string; patch: Partial<Basket> }): void => {
    const existingItem = baskets.find(b => b.id === id);
    if (existingItem) {
      return setBaskets(bs =>
        bs.map(item =>
          item.id !== id
            ? item
            : {
                ...item,
                ...patch,
              },
        ),
      );
    }
    const newItem = { ...productToBasket(getProduct(id)), ...patch };
    return setBaskets(bs => bs.concat(newItem));
  };

  // this function could evolve to handle products with configuration
  const addProductToBasket = ({ id, quantity }: { id: string; quantity: number }) => {
    const existingItem = baskets.find(b => b.id === id);
    const patch = { quantity };

    if (existingItem) {
      patch.quantity += existingItem.quantity;
    }
    upsertItem({ id, patch });
  };

  const updateBasketsByOrderProductId = (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => {
    setBaskets(bs =>
      bs.map(basket => {
        if (basket.orderProductId === id) return { ...basket, ...getBasketToUpdate(basket) };
        return basket;
      }),
    );
  };

  const resetBasket = () => {
    setBaskets([]);
    localStorage.removeItem('baskets');
  };

  const value = React.useMemo(
    () => ({
      getProduct,
      baskets: pickedBaskets,
      amount: totalPrice.getAmount(),
      totalPrice: totalPrice.setLocale('fr-FR').toFormat(),
      nbProducts,
      getBasketById,
      isOffertDisponible,
      addProductToBasket,
      addToBaskets,
      updateBasketsByOrderProductId,
      updateBaskets,
      resetBasket,
      setBaskets,
    }),
    [baskets, cardData],
  );

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
}

export const BasketProvider = React.memo(BasketProviderComponent);
