import React, { useRef, useEffect, useState } from 'react';
import lottie from 'lottie-web';
import get from 'lodash/get';
import { IonText, IonButton } from '@ionic/react';
import { configuration } from 'src/App';
import wait from 'src/animations/wait.json';
import { useOrderPreparingContext } from '../context';
import { ModalResumeOrder } from './ModalResumeOrder';
import Tips from './Tips';
import { MainContainer, Text, FlexContainer, LottieContainer, Time, SuccessTips, SubContainer, Imagecontainer } from './utils-ui';

require('moment/locale/fr.js');

export const New = ({ orderPreparing }) => {
  const { darkTheme, closeLiveOrder } = useOrderPreparingContext();

  return (
    <MainContainer darkTheme={darkTheme}>
      <img alt="cash" src="/img/icon/cash.svg" style={{ height: '20vh', width: '20vh' }} />
      <Text darkTheme={darkTheme} fontWeigth={300} fontSize="1.2em" style={{ padding: '1.5em' }}>
        {`Votre commande est en cours de traitement :`}
      </Text>
      <Text darkTheme={darkTheme} data-cy="order-key" fontWeigth="bold" fontSize="2em">
        {configuration.testMode ? `n°${get(orderPreparing, 'id')}` : `n°${get(orderPreparing, 'key')}`}
      </Text>
      <IonButton onClick={() => closeLiveOrder()} color={darkTheme ? 'light' : 'dark'}>
        <IonText style={{ fontSize: '1em' }}>Retour</IonText>
      </IonButton>
    </MainContainer>
  );
};

const day = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

export const Preparing = ({ orderPreparing }) => {
  const lottiContainerRef = useRef();
  const [open, setOpen] = useState(false);
  const { darkTheme, closeLiveOrder } = useOrderPreparingContext();
  const preorderSlots = orderPreparing?.parameters?.preorderSlots;

  useEffect(() => {
    const defaultOptions = {
      container: lottiContainerRef.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'preparing',
      animationData: wait,
    } as any;
    lottie.loadAnimation(defaultOptions);
    return () => lottie && lottie.destroy('preparing');
  }, []);

  return (
    <MainContainer darkTheme={darkTheme}>
      <FlexContainer>
        <LottieContainer>
          <div style={{ width: '70vh', height: '70vh', position: 'absolute' }} ref={lottiContainerRef} />
        </LottieContainer>
        <Text
          darkTheme={darkTheme}
          fontWeigth={400}
          fontSize="1.2em"
          data-cy="order-prepare"
          width="60%"
          lineHeight="1em"
          justifyContent="center"
          textAlign="center"
        >
          {new Date().getDate() !== new Date(orderPreparing?.created_at).getDate() &&
            preorderSlots &&
            `Votre commande sera preparé pour le ${day[new Date(orderPreparing?.created_at)?.getDay()]} ${new Date(
              orderPreparing?.created_at,
            ).getDate()} à ${preorderSlots}`}

          {new Date().getDate() === new Date(orderPreparing?.created_at).getDate() &&
            preorderSlots &&
            `Votre commande sera preparé pour aujourd'hui à ${preorderSlots}`}

          {!preorderSlots && 'En cours de préparation'}
        </Text>

        <Text darkTheme={darkTheme} fontWeigth="bold" fontSize="2em" data-cy="order-key" paddingBottom="2em" textAlign="center">
          {configuration.testMode ? `n°${get(orderPreparing, 'id')}` : `n°${get(orderPreparing, 'key')}`}
        </Text>
        {orderPreparing?.parameters?.deliveryTime && (
          <>
            <Text
              darkTheme={darkTheme}
              fontWeigth={400}
              fontSize="1.2em"
              data-cy="order-prepare"
              width="60%"
              lineHeight="1em"
              justifyContent="center"
              textAlign="center"
            >
              Votre commande sera prête au alentour de
            </Text>
            <Time darkTheme={darkTheme} fontWeigth="bold" fontSize="2em">
              {`${get(orderPreparing, 'parameters.deliveryTime').split(':')[0]}h${
                get(orderPreparing, 'parameters.deliveryTime').split(':')[1]
              }`}
            </Time>
          </>
        )}
      </FlexContainer>
      {orderPreparing?.divideBill?.numberOfFriends && (
        <Text
          darkTheme={darkTheme}
          fontSize="1em"
          padding="2em"
          paddingTop="0"
          textAlign="center"
        >{`Commande divisé avec ${orderPreparing?.divideBill?.numberOfFriends} de vos amis`}</Text>
      )}
      <IonButton onClick={() => setOpen(true)} color={darkTheme ? 'light' : 'dark'}>
        <IonText style={{ fontSize: '1em' }}>Résumé de ma commande</IonText>
      </IonButton>
      <IonButton onClick={() => closeLiveOrder()} color={darkTheme ? 'light' : 'dark'}>
        <IonText style={{ fontSize: '1em' }}>Retour</IonText>
      </IonButton>
      <ModalResumeOrder open={open} handleClose={() => setOpen(false)} order={orderPreparing} />
    </MainContainer>
  );
};

export const Ready = ({ orderPreparing, deliveryMode }) => {
  const { darkTheme, closeLiveOrder } = useOrderPreparingContext();
  const [open, setOpen] = useState(false);

  return (
    <MainContainer darkTheme={darkTheme}>
      <FlexContainer>
        <img src="/img/utils/food.png" alt="love" style={{ height: '25vh', width: '25vh', marginTop: '4em' }} />
        <Text
          darkTheme={darkTheme}
          fontWeigth={400}
          fontSize="1.3em"
          data-cy="order-ready"
          textAlign="center"
          paddingTop="1em"
          paddingBottom=".5em"
        >
          {deliveryMode === 'delivered' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {"C'est prêt !"}
              <br />
              Allez retirer votre commande
              <br />
              au point de retrait.
              <br />
              <span style={{ fontWeight: 'bold' }}>
                {get(orderPreparing, 'parameters.deliveryInstruction') && `PS: ${get(orderPreparing, 'parameters.deliveryInstruction')}`}
              </span>
            </div>
          )}
          {deliveryMode === 'onplace' && (
            <>
              {"C'est prêt !"}
              <br />
              Un serveur va vous apporter
              <br />
              la commande.
            </>
          )}
        </Text>
        <Text darkTheme={darkTheme} fontWeigth="bold" fontSize="3em">
          n°
          {get(orderPreparing, 'key')}
        </Text>
      </FlexContainer>
      <IonButton onClick={() => setOpen(true)} color={darkTheme ? 'light' : 'dark'}>
        <IonText style={{ fontSize: '1em' }}>Résumé de ma commande</IonText>
      </IonButton>
      <IonButton onClick={() => closeLiveOrder()} color={darkTheme ? 'light' : 'dark'}>
        <IonText style={{ fontSize: '1em' }}>Retour</IonText>
      </IonButton>
      <ModalResumeOrder open={open} handleClose={() => setOpen(false)} order={orderPreparing} />
    </MainContainer>
  );
};

export const Delivered = ({ orderPreparing }) => {
  const { completeOrderPreparing, payTips, tipsSuccess, darkTheme } = useOrderPreparingContext();
  const [tips, setTips] = useState();
  const havePaymentId = orderPreparing?.parameters?.paymentId;

  return (
    <MainContainer darkTheme={darkTheme}>
      {tipsSuccess.success && (
        <SuccessTips darkTheme={darkTheme}>
          <img src="/img/utils/love.svg" alt="love" style={{ height: '25vh', width: '25vh', marginTop: '4em' }} />
          <Text darkTheme={darkTheme} fontWeigth={400} fontSize="1.5em" padding="2em">
            Waouh ! Merci
          </Text>
        </SuccessTips>
      )}
      {tipsSuccess.error && (
        <SuccessTips darkTheme={darkTheme}>
          <Text darkTheme={darkTheme} data-cy="error-tips" fontWeigth={400} fontSize="1.3em">
            {"Une erreur c'est produite vous n'avez pas été debité, (Vous n'avez surement pas enregistré votre carte )"}
          </Text>
        </SuccessTips>
      )}
      <FlexContainer flex=".7" paddingTop="3em">
        <SubContainer darkTheme={darkTheme}>
          <Imagecontainer>
            <img alt="peace" src="/img/utils/peace.svg" style={{ height: '6em', width: '6em' }} />
          </Imagecontainer>
          <Text
            darkTheme={darkTheme}
            fontWeigth={400}
            textAlign="center"
            fontSize="1.3em"
            data-cy="order-delivered"
            lineHeight="1em"
            paddingTop="2em"
          >
            {"Merci d'avoir commandé chez nous !"}
          </Text>
          {havePaymentId && (
            <>
              <Text darkTheme={darkTheme} fontWeigth="bold" textAlign="center" fontSize=".9em" paddingTop="2em" padding=".5em">
                Laisser un pourboir ?
              </Text>
              <Tips darkTheme={darkTheme} setTips={setTips} tips={tips} />
            </>
          )}
          {havePaymentId && (
            <IonButton
              data-cy="validate-tips"
              style={{
                marginTop: '2em',
                '--border-radius': '30px',
                width: '7em',
              }}
              onClick={() => payTips({ tips })}
              color={darkTheme ? 'light' : 'dark'}
            >
              Donner
            </IonButton>
          )}
        </SubContainer>
      </FlexContainer>
      <div style={{ display: 'flex', width: '90%', justifyContent: 'center' }}>
        <IonButton data-cy="refuse-tips" style={{ flex: '0.5' }} onClick={completeOrderPreparing} color={darkTheme ? 'light' : 'dark'}>
          Retour
        </IonButton>
      </div>
    </MainContainer>
  );
};
