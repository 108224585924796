import React, { useEffect, useState } from 'react';
import { FlappyBird } from 'kards-components';
import firebase from 'firebase/app';
import bDay from './background-day.png'
import bNight from './background-night.png'

const useGetHightScore = () => {
  const [dataScore, setDataScore] = useState<{ score: number; name: string }>();
  useEffect(() => {
    firebase
      .app()
      .firestore()
      .collection('games')
      .doc('flappyBird')
      .get()
      .then(doc => {
        const data = doc.data() as { score: number; name: string };
        setDataScore(data);
      });
  }, []);

  return [dataScore, setDataScore] as const;
};

export default ({ component }) => {
  const data = component.value
  const [dataScore, setDataScore] = useGetHightScore();

  const handleChange = ({ score, name }) => {
    if (score && name) {
      setDataScore({ score, name });
      firebase
        .app()
        .firestore()
        .collection('games')
        .doc('flappyBird')
        .set({ score, name });
    }
  };

  return <FlappyBird background={data.background} hightScore={dataScore} updateHightScore={handleChange} />;
};
