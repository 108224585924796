import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useQuery } from 'src/data/hooks/useQuery';
import { Text, ButtonLink, Footer, Content } from 'src/common-ui';
import { useParams, useHistory } from 'react-router-dom';
import { getOrderById, usePaymentOnSnapshot } from '../../../data/accessor/api';
import { toEuro } from '../utils';
import { useSetOrderToPreparing } from '../components/useSetOrderToPreparaing';

export function DivideBillPaymentStatus() {
  const history = useHistory();
  const { id: orderId } = useParams() as { id: string };

  const { data: order } = useQuery(() => getOrderById(orderId));
  const payments = usePaymentOnSnapshot(orderId);

  const numberOfFriends: number = order?.divideBill?.numberOfFriends;
  const totalPrice: number = order?.divideBill?.totalPrice || 1;
  const totalPayed = payments ? payments.map(p => p.amount).reduce((a, b) => a + b, 0) : 0;
  const percentage = Math.round((totalPayed * 100) / totalPrice);

  useSetOrderToPreparing({ orderId, percentage, threshold: 95 });

  return (
    <Content fullscreen>
      <div
        style={{
          padding: '0 30px 100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',

          paddingBottom: '100px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '15px', height: '160px' }}>
          {order && <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={1} />}
        </div>

        <div style={{ border: '2px solid grey', padding: '10px 0px' }}>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
            Nombre de personne :
            <span style={{ fontWeight: 'bold', marginLeft: '5px' }} data-cy="numberOfFriends">
              {numberOfFriends}
            </span>
          </Text>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
            Coût total :
            <span style={{ fontWeight: 'bold', marginLeft: '5px' }} data-cy="totalPrice">
              {toEuro(totalPrice)}
            </span>
          </Text>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
            Déjà payé :
            <span style={{ fontWeight: 'bold', marginLeft: '5px' }} data-cy="totalPayed">
              {toEuro(totalPayed)}
            </span>
          </Text>
        </div>

        <Text margin="15px 0" textAlign="center" style={{ display: 'inline' }}>
          <span style={{ fontWeight: 'bold' }}>Attention ! </span>
          La commande ne sera validée qu&apos;une fois le coût total payé.
        </Text>

        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <ButtonLink to={`/bill/divide/payment-choose/${orderId}`} data-cy="btn-paid-other-one">
            Payer une autre part
          </ButtonLink>
        </div>
      </div>
      <Footer type="button" title="Revenir à la carte" onClick={() => history.push('/')} />
    </Content>
  );
}
