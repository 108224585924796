import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Order } from '../types';
import { flatMap, uniq, map, shuffle } from 'lodash';
import { useBasketContext } from 'src/modules/basket';
import { useReadContext } from 'src/modules/card/context';

export function useQuery<T>(apiClient: () => Promise<T>, trigger = []) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  useEffect(() => {
    setLoading(true);
    apiClient()
      .then(setData)
      .catch(err => setError(err))
      .then(() => setLoading(false));
  }, trigger);
  return { data, loading, error };
}

export function getLastOrdersFromUser(userId) {
  if (!userId) {
    return Promise.resolve([]);
  }
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .where('userId', '==', userId)
    .orderBy('created_at', 'desc')
    .limitToLast(10)
    .get()
    .then(q => {
      let result = [];
      q.forEach(d => {
        result.push(d.data());
      });
      return result as Order[];
    });
} 

export function useLast5ProductsFromUser() {
  const { user }: any = useReadContext()
  const { data: orders }: any = useQuery(() => getLastOrdersFromUser(user?.uid));
  const productIds = uniq(
    map(
      flatMap(orders, order => order.items),
      p => p.id,
    ),
  );
  const { getProduct } = useBasketContext();

  return shuffle(productIds).map(id => getProduct(id)).filter(p => p?.id).slice(0, 5);
}

