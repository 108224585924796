import React from 'react';
import { Product } from 'kards-components';

import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { useTheme } from 'src/modules/card/context/theme';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';

const LinkWithCheck = ({ stock, settingFields, to, description, ...props }) =>
  (settingFields && (get(stock, 'unlimited') || parseInt(get(stock, 'stockNumber'), 10) > 0)) ||
  (description && description.length > 60) ? (
    <Link to={to} {...props} />
  ) : (
    <span {...props} />
  );

export default ({ component }) => {
  const data = component.value
  const theme = useTheme();
  const { trackAddProductToBasket } = useAnalyticsContext()

  return (
    <LinkWithCheck
      key={get(data, 'id')}
      style={{
        textDecoration: 'none',
      }}
      description={get(data, 'description')}
      stock={get(data, 'stock')}
      settingFields={get(data, 'settingFields') && get(data, 'settingFields').length > 0}
      to={{
        pathname: '/product',
        state: { product: data, component },
      }}
    >
      <Product
        theme={theme}
        name={get(data, 'reference')}
        stock={get(data, 'stock')}
        picture={get(data, 'picture')}
        id={get(data, 'id')}
        description={get(data, 'description')}
        settingFields={get(data, 'settingFields')}
        price={get(data, 'price')}
        tva={get(data, 'tva')}
        key={get(data, 'id')}
        width="100%"
        read
      >
        <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })}/>
      </Product>
    </LinkWithCheck>
  );
};
