import React from 'react';
import { Carousel } from 'kards-components';
import { SimpleList } from 'kards-components';
import useMedia from 'use-media';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';

export default function CarrouselOrSimpleList({ component, data: { id, products = [], title, description }, icon }: any) {
  const theme = useTheme();
  const isWide = useMedia({ minWidth: '500px' });
  const { trackAddProductToBasket } = useAnalyticsContext()

  if (products.every(p => p.picture)) {
    return (
      <Carousel key={id} read theme={theme} data={{ products, title, description }} width={isWide ? '20vw' : '70vw'}>
        <Wrapper>
          {({ id }) => (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })} id={id} />
              <div>{icon}</div>
            </div>
          )}
        </Wrapper>
      </Carousel>
    );
  }

  return (
    <SimpleList key={id} theme={theme} data={{ products, title, description }}>
      <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })} />
    </SimpleList>
  );
}

function Wrapper({ children, ...props }: any) {
  return <>{children(props)}</>;
}
