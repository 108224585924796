import React from 'react';
import { IonText, IonCol, IonRow,IonList, IonItem,  } from '@ionic/react';
import Dinero from 'dinero.js';
import styled from 'styled-components';
import { colors } from 'kards-components';
import { useTheme } from 'src/modules/card/context/theme';
import { useHistory } from 'react-router-dom';
import { AddRemoveBasketRecap } from 'src/modules/basket';
import { useBasketContext } from 'src/modules/basket';

export function BasketsRecap() {
  const theme = useTheme();
  const history = useHistory();
  const { baskets: basketProducts } = useBasketContext();

  if (!basketProducts.length) return null;
  return (
    <List color={theme?.color?.productTitle} bgColor={theme?.color?.background} lines="none">
      {basketProducts.map(product => (
        <Item
          key={product.orderProductId}
          color={theme?.color?.background}
          lines="none"
          onClick={() => {
            if (product.settingFields) {
              history.push(`/${product.type}/${product.orderProductId}`, { [product.type]: product });
            }
          }}
        >
          <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
            <IonText color={theme?.color?.productTitle}>{product.reference}</IonText>
            <div>
              <TextPrice color={theme?.color?.productTitle}>
                {Dinero({ amount: product.price, currency: 'EUR' })
                  .setLocale('fr-FR')
                  .toFormat()}
              </TextPrice>
            </div>
          </IonCol>
          {product?.settingFields && (
            <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{`x ${product.quantity}`}</div>
            </IonCol>
          )}
          <IonCol>
            <IonRow className="ion-justify-content-end">
              <AddRemoveBasketRecap id={product.id} />
            </IonRow>
          </IonCol>
        </Item>
      ))}
    </List>
  );
}

export const TextPrice = styled(IonText)`
  border: ${props => colors[props.color]} solid 1px;
  border-radius: 15px;
  padding: 0 7px;
  font-size: 0.8em;
`;

export const Item = styled(IonItem)`
  background-color: ${props => colors[props.color]};
  padding: 0.2em 0.5em;
  flex: 1;
`;

export const List = styled(IonList)`
  background-color: ${props => colors[props.bgColor]};
  margin: 1.5em;
  border-radius: 10px;
  border: 1px solid ${props => colors[props.color]};
`;
