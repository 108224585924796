import React, { createContext, useContext, useRef } from 'react';
import { noop } from 'lodash';
import { v4 } from 'uuid'
import firebase from 'firebase';
import { eventNames } from './eventNames';
import { configuration } from 'src/App';

const logToFirebase = async data => {
  if (configuration.testMode) return

  try {
    await firebase
      .app()
      .firestore()
      .collection('analytics')
      .doc(data.id)
      .set(data);
  } catch (e) {
    console.log('nardine', e);
  }
}

export const AnalyticsContext = createContext({
  eventsId: [],
  logEvent: noop,
  trackAddProductToBasket: ({ component }) => ({ id, quantity }) => { },
});


export function useAnalyticsContext() {
  return useContext(AnalyticsContext);
}

export function AnalyticsProvider({ children, cardId, categories }) {
  const eventsId = useRef<string[]>([])
  const logEvent = useRef<(data: any) => void>(async (data) => {
    let category
    let position
    let componentType

    // in case we log events not related to components, i.e openApplication
    if (data.componentId) {
      categories.forEach(cat => {
        const pos = cat.components.findIndex(c => c.id === data.componentId)
        if (pos > -1) {
          componentType = cat.components[pos].type
          position = pos
          category = cat
        }
      })
    }

    const user = firebase.auth().currentUser;
    const finalData = {
      ...data,
      date: Date.now(),
      userId: user?.uid || null,
      cardId,
      // might need to create our own session id system here, cant find how to retrieve one from firebase
      id: v4()
    }
    if (finalData.componentId) {
      finalData.position = position
      finalData.componentType = componentType
      finalData.categoryId = category.id
    }

    await logToFirebase(finalData)
    eventsId.current.push(finalData.id)
  })

  const trackAddProductToBasket = ({ component }) => ({ id, quantity }) => {
    logEvent.current({
      type: eventNames.addProductToBasket,
      componentId: component.id,
      productId: id,
      quantity,
    })
  }
  const value = useRef({
    eventsId: eventsId.current,
    logEvent: logEvent.current,
    trackAddProductToBasket,
  })

  return <AnalyticsContext.Provider value={value.current}>{children}</AnalyticsContext.Provider>;
}
