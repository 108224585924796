import React, { useState } from 'react';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import moment from 'moment';
import FlappyBird from 'src/modules/card/kardComponents/FlappyBird';
import { Content, Text, Chip } from 'src/common-ui';
import { useOnFireContext } from './context';
import { arrowBack } from 'ionicons/icons';
import { useInterval } from 'src/helpers/useInterval';

function formatTime(onFireTime: number) {
  if (!onFireTime || onFireTime <= Date.now()) return 0;
  return moment.duration(onFireTime - Date.now()).minutes();
}

export function OnFireModal({ onFire }) {
  const { isOpen, setOpenModal } = useOnFireContext();

  const [time, setTime] = useState(formatTime(onFire));
  useInterval(() => setTime(formatTime(onFire)), [onFire], 60 * 1000);

  return (
    <IonModal isOpen={isOpen}>
      <Content fullscreen>
        <div>
          <IonButton fill="clear" onClick={() => setOpenModal(false)}>
            <IonIcon icon={arrowBack} />
          </IonButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: '20px',
            padding: '0 20px',
            paddingBottom: '60px',
          }}
        >
          <Text fontWeigth="bold" fontSize="26px" textAlign="center" padding="0 20px" marginBottom="12px">
            Nous sommes victime de notre succès !
          </Text>
          <Text textAlign="center">Wouhou!</Text>
          <Text textAlign="center" padding="0 10px">
            Vous êtes trop nombreux à commander, les commandes sont en pause le temps de vous servir !
          </Text>
          <div style={{ marginTop: '16px', marginBottom: '10px' }}>
            <Chip>{time || "> 1"} min</Chip>
          </div>
          <Text fontWeigth="bold" textAlign="center">
            Temps d'attente estimé
          </Text>

          <div style={{ position: 'relative', marginTop: '4em', marginBottom: '10px', width: '100%' }}>
            <FlappyBird
              component={{
                value: {
                  background: 'https://firebasestorage.googleapis.com/v0/b/dev-card-4bdab.appspot.com/o/images%2F016bf9cd-1fef-487a-bf59-ef1ba717656c%2Foriginal?alt=media&token=1587936074999',
                }
              }}
            />
          </div>
          <Text fontWeigth="bold">Patiente en battant le record !</Text>
        </div>
      </Content>
    </IonModal>
  );
}
