import React, { useEffect, useState, Suspense } from 'react';
import { IonApp, IonAlert } from '@ionic/react';
import Media from 'react-media';
import { colors } from 'kards-components';
import { OrderPreparingProvider } from './modules/liveOrder/context';
import { useWatchApplication } from './data/hooks/watchApplicationCard';
import { isPrivateWindow } from './helpers/incognito';
import { Main } from './modules/Main';
import './firebase';
import 'intersection-observer';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './App.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import { BrowserRouter as Router } from 'react-router-dom'; 
import { PrivateNavigation, Layout } from './common-ui';

const windowT: any = window;
export const configuration = windowT.kardsConfiguration;

export default function App() {
  const [isGlobalError, setIsGlobalError] = useState({ error: false, message: null });
  const { pwa }: any = useWatchApplication({ error: isGlobalError });

  const [isPrivateMode, setIsPrivateMode] = useState(false);
  useEffect(() => {
    isPrivateWindow(setIsPrivateMode);
  }, []);


  return (
    <Router>
      <Media queries={{ small: '(max-width: 599px)', medium: '(min-width: 600px)' }}>
        {matches => (
          <Layout matches={matches}>
            <IonApp style={{ touchAction: 'manipulation', background: colors[pwa?.compiledData?.theme?.color?.background] }}>
              {isPrivateMode ? (
                <PrivateNavigation />
              ) : (
                <>
                  <IonAlert
                    isOpen={isGlobalError.error}
                    onDidDismiss={() => {
                      setIsGlobalError({ error: null, message: null });
                    }}
                    header="Oups ..."
                    subHeader=""
                    message={isGlobalError.message ? isGlobalError.message : 'Il semble avoir eu un problème sur votre application'}
                    buttons={[
                      {
                        text: 'Recharger mon application',
                        role: 'validate',
                        cssClass: 'secondary',
                        handler: () => window.location.reload(),
                      },
                    ]}
                  />
                  <Suspense fallback={<div />}>
                    <OrderPreparingProvider>
                      <Main pwa={pwa} />
                    </OrderPreparingProvider>
                  </Suspense>
                </>
              )}
            </IonApp>
          </Layout>
        )}
      </Media>
    </Router>
  );
}
