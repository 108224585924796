import { useEffect } from 'react';
import { useOrderPreparingContext } from 'src/modules/liveOrder';
import { useBasketContext } from 'src/modules/basket';

export function useSetOrderToPreparing({ orderId, percentage, threshold = 100 }) {
  const { setOrderId } = useOrderPreparingContext();
  const { resetBasket } = useBasketContext();

  useEffect(() => {
    if (percentage >= threshold) {
      setOrderId(orderId);
      resetBasket();
    }
  }, [percentage]);
}
