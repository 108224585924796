import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Order } from '../types';
import { flatMap, uniq, map } from 'lodash';
import { useBasketContext } from 'src/modules/basket';

export function useQuery<T>(apiClient: () => Promise<T>, trigger = []) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  useEffect(() => {
    setLoading(true);
    apiClient()
      .then(setData)
      .catch(err => setError(err))
      .then(() => setLoading(false));
  }, trigger);
  return { data, loading, error };
}

export function getLastOrders() {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .orderBy('created_at', 'desc')
    .limitToLast(10)
    .get()
    .then(q => {
      let result = [];
      q.forEach(d => {
        result.push(d.data());
      });
      return result as Order[];
    });
}

export function useLast5Products() {
  const { data: orders }: any = useQuery(() => getLastOrders());
  const productIds = uniq(
    map(
      flatMap(orders, order => order.items),
      p => p.id,
    ),
  )?.slice(0, 5);
  const { getProduct } = useBasketContext();
  return productIds.map(id => getProduct(id)).filter(p => p?.id);
}
