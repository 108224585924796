import React, { useEffect } from 'react';
import { forEach } from 'lodash';
import { Formik } from 'formik';
import { useHistory, useLocation, Redirect, useParams } from 'react-router-dom';
import { useBasketContext } from 'src/modules/basket';
import { Form, SettingField } from './Form';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';
import { eventNames } from 'src/analytics/eventNames';

export function ViewProduct({ onSubmit, product, quantity }) {
  const validate = values => {
    const errors: any = {};
    if (values.options) {
      forEach(values.options, (option, i) => {
        product.settingFields.forEach(champ => {
          if (champ.label === i) {
            if (champ.required) {
              if (!option || (option && option.length === 0)) {
                if (!errors.options) {
                  errors.options = {};
                }
                errors.options[i] = 'Champs requis';
              }
            }
            if (typeof option === 'object' && option && option.length > parseInt(champ.optionsNumber, 10)) {
              if (!errors.options) {
                errors.options = {};
              }
              errors.options[i] = `Maximum ${champ.optionsNumber} choix`;
            }
          }
        });
      });
    } else if (product && product.settingFields) {
      product.settingFields.forEach(champ => {
        if (champ.required) {
          if (!errors.options) {
            errors.options = {};
          }
          errors.options[champ.label] = 'Champs requis';
        }
      });
    }
    return errors;
  };

  const initialValues = {
    id: product.id,
    reference: product.reference,
    settingFields: Object.values(product.settingFields || []),
    options: product.options,
    quantity: quantity || 1,
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values);
      }}
      validate={validate}
    >
      {({ errors }) => <Form displayQuantity={quantity !== null} entity={product} errors={errors} SettingsFieldComponent={SettingField} />}
    </Formik>
  );
}

export default function Product() {
  const { addToBaskets, updateBasketsByOrderProductId, addProductToBasket } = useBasketContext();
  const { state }: any = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const { logEvent, trackAddProductToBasket } = useAnalyticsContext()


  const product: any = {
    ...(state?.product || {}),
    settingFields: Object.values(state?.product?.settingFields || []), // fix bug object to array
  };

  const component: any = state?.component;
  useEffect(() => {
    if (component) {
      logEvent({
        type: eventNames.productDetailViewed,
        componentId: component.id,
        productId: product.id,
      })
    }
  }, [])

  if (!product) return <Redirect to="/" />;

  return (
    <ViewProduct
      product={product}
      quantity={product.quantity || 1}
      onSubmit={values => {
        if (id) {
          updateBasketsByOrderProductId(id, () => ({ options: values.options, quantity: values.quantity }));
        } else if (!product.settingFields) {
          addProductToBasket({ id: values.id, quantity: values.quantity });
        } else {
          addToBaskets(values.id, { options: values.options, quantity: values.quantity });
        }
        if (component) {
          trackAddProductToBasket({ component })({ id: values.id, quantity: values.quantity })
        }
        history.goBack();
      }}
    />
  );
}
