import Dinero from 'dinero.js';
import { Basket } from 'src/data/types';

export function getTotalPrice(basketProducts: Basket[]) {
  if (!basketProducts) return Dinero({ amount: 0, currency: 'EUR' });

  const line = basketProducts.map(item =>
    Dinero({ amount: item.price })
      .multiply(item.quantity)
      .getAmount(),
  );

  let amount = Dinero({ amount: 0, currency: 'EUR' });
  line.forEach(a => {
    amount = amount.add(Dinero({ amount: a, currency: 'EUR' }));
  });

  return amount;
}

export function sumTotal(tabAmount: number[]) {
  let amount = Dinero({ amount: 0, currency: 'EUR' });
  tabAmount.forEach(a => {
    amount = amount.add(Dinero({ amount: a, currency: 'EUR' }));
  });
  return amount;
}

export function getDividePrice({ numberOfFriends, totalPrice }) {
  const dividePrice = !numberOfFriends || numberOfFriends <= 0 ? totalPrice : totalPrice.divide(numberOfFriends);
  return dividePrice;
}

export function toEuro(amount: number) {
  if (!amount) return '0 €';
  return Dinero({ amount, currency: 'EUR' })
    .setLocale('fr-FR')
    .toFormat();
}

export const toEuroDinero = (dineroAmount: Dinero.Dinero) => dineroAmount.setLocale('fr-FR').toFormat();
