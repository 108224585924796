import React from 'react';
import {
  IonList,
  IonItem,
  IonText,
  IonCol,
  IonModal,
  IonRow,
  IonButton,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
} from '@ionic/react';
import { map, forEach } from 'lodash';
import styled from 'styled-components';
import { colors } from 'kards-components';
import { useOrderPreparingContext } from '../context';
import { arrowBack } from 'ionicons/icons';
import Dinero from 'dinero.js';

const DARK = '#222428';
const WHITE = 'white';

function sumAmount(order) {
  let amount = 0;

  forEach(order.items, item => {
    let quantity = item.quantity || item.number;
    const multiple = Dinero({ amount: parseInt(item.price, 10) }).multiply(parseInt(quantity, 10));
    amount += multiple.getAmount()
  });

  return amount;
}

export function ModalResumeOrder({ order, open, receipe = false, handleClose }) {
  const { darkTheme } = useOrderPreparingContext();
  const total = order?.totalPrice || sumAmount(order);

  return (
    <IonModal isOpen={open} showBackdrop={false}>
      <BoxWrapper bgcolor={darkTheme ? DARK : WHITE} display="flex" flexDirection="column">
        <Header receipe={receipe} handleClose={handleClose} darkTheme={darkTheme} />
        <IonListWrapper border={darkTheme ? WHITE : DARK} color={darkTheme ? DARK : WHITE} lines="none">
          {map(order?.items, ({ price, id, quantity, reference }) => (
            <IonItemWrapper color={darkTheme ? DARK : WHITE} key={id} lines="none">
              <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                <IonText color={darkTheme ? 'light' : 'dark'}>{reference}</IonText>
                <div>
                  <IonTextPrice color={darkTheme ? 'light' : 'dark'}>
                    {Dinero({ amount: parseInt(price, 10), currency: 'EUR' })
                      .setLocale('fr-FR')
                      .toFormat()}
                  </IonTextPrice>
                </div>
              </IonCol>
              <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                <IonRow className="ion-justify-content-end">
                  <IonText color={darkTheme ? 'light' : 'dark'}>x{quantity}</IonText>
                </IonRow>
              </IonCol>
            </IonItemWrapper>
          ))}
          <IonItemWrapper color={darkTheme ? DARK : WHITE}>
            <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
              <IonText style={{ fontWeight: 'bold' }} color={darkTheme ? 'light' : 'dark'}>
                Total
              </IonText>
            </IonCol>
            <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
              <IonRow className="ion-justify-content-end">
                <IonText style={{ fontWeight: 'bold' }} color={darkTheme ? 'light' : 'dark'}>
                  {Dinero({ amount: total, currency: 'EUR' })
                    .setLocale('fr-FR')
                    .toFormat()}
                </IonText>
              </IonRow>
            </IonCol>
          </IonItemWrapper>
        </IonListWrapper>
      </BoxWrapper>
    </IonModal>
  );
}

const Header = ({ handleClose, darkTheme, receipe }) => (
  <IonHeader>
    <IonToolbar color={darkTheme ? 'dark' : 'light'}>
      <IonButtons slot="start">
        <IonButton fill="clear" onClick={handleClose}>
          <IonIcon icon={arrowBack} />
        </IonButton>
      </IonButtons>
      <IonTitle style={{ textAlign: 'center' }}>{receipe ? 'Reçu' : 'Résumé'}</IonTitle>
    </IonToolbar>
  </IonHeader>
);

const BoxWrapper = styled.div`
  overflow: scroll;
  background: ${props => props.bgcolor};
  justify-content: 'center';
  align-items: 'center';
  display: 'flex';
  height: 100%;
  :focus {
    outline-width: 0;
  }
`;

const IonListWrapper = styled(IonList)`
  background-color: ${props => props.color};
  margin: 1.5em;
  border-radius: 10px;
  border: 1px solid ${props => props.border};
`;

const IonItemWrapper = styled(IonItem)`
  padding: 5px;
  background-color: ${props => props.color};
  flex: 1;
`;
const IonTextPrice = styled(IonText)`
  border: ${props => colors[props.color]} solid 1px;
  border-radius: 15px;
  padding: 0 7px;
  font-size: 0.8em;
`;
