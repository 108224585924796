import React, { useContext } from 'react';
import { SocialMedia } from 'kards-components';

import { ThemeContext } from 'src/modules/card/context/theme';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';
import { eventNames } from 'src/analytics/eventNames';

export default ({ component }) => {
  const { facebook, twitter, instagram } = component.value
  const {
    theme,
  }: any = useContext(ThemeContext);
  const { logEvent } = useAnalyticsContext()
  return (
    <SocialMedia
      theme={theme}
      facebook={facebook}
      twitter={twitter}
      instagram={instagram}
      onClick={(socialNetworkName) => logEvent({
        type: eventNames.clickSocialNetwork,
        componentId: component.id,
        socialNetworkName,
      })}
    />
  );
};
