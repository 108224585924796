import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useBasketContext } from 'src/modules/basket';
import { useOrderById } from 'src/data/accessor/api';
import { useOrderPreparingContext } from 'src/modules/liveOrder';

export const ButtonSameUI = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 10vh;
  right: 10px;
  height: 9vh;
  width: 9vh;
  box-shadow: 0px 0px 30px -11px #a5a5a5;
  border-radius: 60px;
  overflow: visible;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ButtonSameOrder() {
  const { lastOrderId, setLastOrderId } = useOrderPreparingContext();
  const { data: order } = useOrderById(lastOrderId);
  const history = useHistory();
  const { setBaskets } = useBasketContext();
  const baskets = Object.values(order?.items || []);

  if (!order || !lastOrderId) return null;

  return (
    <ButtonSameUI
      onClick={() => {
        setBaskets(baskets);
        history.push('/orders/confirm');
      }}
    >
      <img data-cy="same-order" src="/img/utils/same.svg" style={{ height: '9vh', width: '9vh' }} alt="" />
    </ButtonSameUI>
  );
}
