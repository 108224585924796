import React from 'react';
import { CardProvider } from 'src/modules/card/context';
import { BasketProvider } from 'src/modules/basket';
import ThemeProvider from 'src/modules/card/context/theme';
import Header from 'src/common-ui/header';
import { Routes } from './Routes';
import { OnFireProvider, OnFireModal } from 'src/modules/onFire';
import { useOrderPreparingContext } from './liveOrder/context';
import { AnalyticsProvider } from 'src/analytics/AnalyticsContext';

const LiveOrder = React.lazy(() => import('./liveOrder/LiveOrder'));

export function Main({ pwa }) {
  const { isOpen } = useOrderPreparingContext();

  if (isOpen) return <LiveOrder />;
  return <Card pwa={pwa} />;
}

function Card({ pwa }) {
  const { compiledData, open, averageTime = 0, start } = pwa;

  const isLoading = !compiledData || Object.keys(compiledData).length === 0;

  if (isLoading)
    return (
      <img
        data-cy="screenOff"
        alt="screenOff"
        src="/img/kards/logo.svg"
        style={{ background: 'white', height: '100%', width: '100%', padding: '20px' }}
      />
    );

  return (
    <OnFireProvider onFire={pwa.onFire}>
      <AnalyticsProvider cardId={pwa.cardId} categories={compiledData.components}>
        <CardProvider open={open} averageTime={averageTime} apiData={compiledData} start={start} onFire={pwa.onFire}>
          <BasketProvider>
            <ThemeProvider theme={compiledData?.theme}>
              <Header orderMode={compiledData?.order_mode} template={compiledData?.template.name} />
              {Object.keys(compiledData).length > 0 && <Routes />}
              {pwa.onFire && <OnFireModal onFire={pwa.onFire} />}
            </ThemeProvider>
          </BasketProvider>
        </CardProvider>
      </AnalyticsProvider>
    </OnFireProvider>
  );
}
