import { ReactStateUpdate } from "./TStypes";

export const getStateUpdate = <T>({
  update,
  state,
}:{
  update: ReactStateUpdate<T>,
  state: T,
}): T => (
  update instanceof Function
  ? update(state)
  : update
);
