import React, { useState } from 'react';
import firebase from 'firebase/app';
import Dinero from 'dinero.js';
import { IonButton, IonIcon } from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Text, Error, Footer } from 'src/common-ui';
import { useBasketContext } from 'src/modules/basket';
import { createOrderFunction } from 'src/data/accessor/api';
import { useReadContext } from 'src/modules/card/context';
import { FormikLayout } from '../components/FormikLayout';
import { getTotalPrice, getDividePrice, toEuroDinero } from '../utils';
import { addOutline, removeOutline } from 'ionicons/icons';
import { useTheme } from 'src/modules/card/context/theme';

export function DivideBill() {
  const theme = useTheme();
  const history = useHistory();
  const [numberOfFriends, setNumberOfFriends] = useState(2);
  const { baskets } = useBasketContext();
  const { state = {} }: any = useLocation();
  const { user }: any = useReadContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | boolean>();

  const totalPrice = getTotalPrice(baskets);
  const dividePrice = getDividePrice({ numberOfFriends, totalPrice });
  const restDivision = totalPrice.subtract(dividePrice.multiply(numberOfFriends || 1));
  const amountToPay = dividePrice.add(restDivision);

  const submit = async () => {
    try {
      setLoading(true);
      const order = {
        baskets,
        type: 'divide',
        userId: [user?.uid],
        divideBill: {
          numberOfFriends,
          totalPrice: totalPrice.getAmount(),
          dividePrice: dividePrice.getAmount(),
          restDivision: restDivision.getAmount(),
        },
        parameters: {
          ...(state || {}),
          phone_number: user?.phoneNumber,
        },
      };
      const result = await createOrderFunction(order);
      if (result.data.error) {
        setError(result.data);
        return;
      }
      const orderId = result.data;
      setLoading(false);
      firebase.analytics().logEvent('button divide', { orderId });
      history.push(`/bill/divide/share-link/${orderId}`);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  if (error) return <Error error={error} resetError={() => setError(false)} />;

  return (
    <FormikLayout initialValues={{}} onSubmit={submit}>
      <Text fontWeigth="bold" fontSize="22px" margin="20px 0 20px 0" textAlign="center">
        Diviser la note avec vos amis
      </Text>

      <Text margin="10px" textAlign="center">
        Montant à diviser
      </Text>
      <div
        style={{
          backgroundColor: '#919193',
          display: 'flex',
          padding: '4px 20px',
          borderRadius: '30px',
          marginBottom: '50px',
        }}
      >
        <Text fontWeigth="800" fontSize="22px" textAlign="center" revertColor data-cy="total-price">
          {toEuroDinero(totalPrice)}
        </Text>
      </div>

      <Text position="floating">Nombre de personne</Text>
      <div
        style={{
          marginTop: '12px',
          marginBottom: '40px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ButtonNbPeople
          color={theme?.color?.productTitle}
          size="small"
          onClick={() => setNumberOfFriends(n => (n <= 2 ? 2 : n - 1))}
          disabled={numberOfFriends <= 2}
          data-cy="btn-remove-friends"
        >
          <Icon color={theme?.color?.background} icon={removeOutline} fontSize="1.7em" mode="ios" />
        </ButtonNbPeople>
        <div style={{ fontSize: '42px', fontWeight: 900, margin: '0 50px' }} data-cy="nb-friends">
          {numberOfFriends}
        </div>
        <ButtonNbPeople
          color={theme?.color?.productTitle}
          size="small"
          onClick={() =>
            setNumberOfFriends(n => {
              if (amountToPay.lessThanOrEqual(Dinero({ amount: 100, currency: 'EUR' }))) {
                return n;
              }
              return n + 1;
            })
          }
          data-cy="btn-add-friends"
        >
          <Icon color={theme?.color?.background} icon={addOutline} fontSize="1.7em" mode="ios" />
        </ButtonNbPeople>
      </div>

      <Text position="floating" margin="0 0 10px 0">
        {restDivision.isZero() ? 'Montant à payer par personne' : 'Montant à payer pour vous'}
      </Text>
      <Text fontWeigth="700" fontSize="36px" data-cy="amountToPay">
        {toEuroDinero(amountToPay)}
      </Text>

      {!restDivision.isZero() && (
        <Text margin="20px 0 0" data-cy="restDivision">{`Montant à payer pour vos amis : ${toEuroDinero(dividePrice)}`}</Text>
      )}
      <Footer type="submit" loading={loading} title="Valider" data-cy="btn-valider" />
    </FormikLayout>
  );
}

const ButtonNbPeople = styled(IonButton)`
  height: 43px;
`;

export const Icon = styled(IonIcon)`
  font-size: ${props => props.fontSize};
`;
