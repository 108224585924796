import React, { createContext, useState, useEffect, useContext } from 'react';
import localforage from 'localforage';
import { createPaymentIntentsFromExisting } from 'src/data/accessor/stripe';
import { useWatchOrder } from 'src/data/hooks/watchOrders';
import { getCurrentStep } from 'kards-components';
import { log } from 'src/helpers/logger';
import { useHistory } from 'react-router-dom';
import { Order } from 'src/data/types';

type ContextType = {
  darkTheme: boolean;

  lastOrderId: string;
  setLastOrderId: (orderId: string) => void;

  isOpen: boolean;

  order: Order;
  status: string;

  orderIdPreparing: string;
  setOrderId: (orderId: string) => void;
  completeOrderPreparing: () => void;
  closeLiveOrder: () => void;

  tipsSuccess: { error?: boolean; success?: boolean };
  payTips: ({ tips }: { tips: any }) => Promise<void>;
};

export const OrderPreparingContext = createContext<ContextType>({} as ContextType);

export function useOrderPreparingContext() {
  return useContext(OrderPreparingContext);
}

const LIMIT_LIGHT_MOD = 20;

export function OrderPreparingProvider({ children }) {
  const [lastOrderId, setLastOrderId] = useState<string>(); // for button order same
  const [orderIdPreparing, setOrderIdPreparing] = useState<string>(); // to show LiveOrder
  const [tipsSuccess, setTipsSuccess] = useState<{ error?: boolean; success?: boolean }>({ error: false, success: false });
  const history = useHistory();
  const darkTheme = useDarkMode();

  const order = useWatchOrder({ id: orderIdPreparing });
  const status = order ? getCurrentStep(order) : undefined;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (order) setIsOpen(true);
  }, [order]);
  console.log('HERE', lastOrderId)

  useEffect(() => {
    localforage.getItem('orderPreparing').then((value: string) => {
      console.log('HERE', value)

      setOrderIdPreparing(value || '');
      setLastOrderId(value);
    });
  }, []);

  const payTips = async ({ tips }) => {
    if (!tips) return;

    setTipsSuccess({ success: true });
    const { error } = await createPaymentIntentsFromExisting({
      amount: tips,
      orderId: orderIdPreparing,
    });
    if (error) {
      log('createPaymentIntentsFromExisting_error', { error });
      setTipsSuccess({ error: true });
    }

    const time = error ? 3000 : 1000;
    setTimeout(() => {
      completeOrderPreparing();
    }, time);
  };

  const setOrderId = (orderId: string) => {
    setIsOpen(true);
    localforage.setItem('orderPreparing', orderId);
    setOrderIdPreparing(orderId);
    setLastOrderId(orderId);
  };

  const closeLiveOrder = async () => {
    setIsOpen(false);
    history.push('/');
  };

  const completeOrderPreparing = async () => {
    setIsOpen(false);
    setOrderIdPreparing('');
    await localforage.removeItem('orderPreparing');
    setTipsSuccess({ error: false, success: false });
    history.push('/');
  };

  const value = {
    darkTheme,
    tipsSuccess,
    payTips,

    isOpen,

    order,
    status,

    orderIdPreparing,
    setOrderId,
    completeOrderPreparing,
    closeLiveOrder,

    lastOrderId,
    setLastOrderId,
  };

  return <OrderPreparingContext.Provider value={value}>{children}</OrderPreparingContext.Provider>;
}

function useDarkMode() {
  const [darkTheme, setDarkTheme] = useState(false);
  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= LIMIT_LIGHT_MOD) setDarkTheme(true);
  }, []);

  return darkTheme;
}
