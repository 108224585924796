import { useState, useEffect } from 'react';
import firebase from 'firebase';
import localforage from 'localforage';

export const useWatchApplication = ({ error }) => {
  const [data, setData] = useState({
    compiledData: {},
  });

  useEffect(() => {
    const defaultFirestore = firebase.app();

    return defaultFirestore
      .firestore()
      .collection('application')
      .doc('pwa')
      .onSnapshot(
        {},
        async doc => {
          const card: any = doc.data();
          if (card) {
            const lastupdate = await localforage.getItem('lastupdate');
            const version = await localforage.getItem('version');
            if (!version) {
              localforage.setItem('version', card.version);
            }

            if (version && version !== card.version) {
              await localforage.setItem('version', card.version);
              window.location.reload();
            }
            // stringify because loforage stringify
            const lastUpdateCard = `${card.lastupdate}`;

            if (!lastupdate) {
              localforage.setItem('lastupdate', lastUpdateCard);
            }
            // eslint-disable-next-line
            if (lastupdate && lastupdate !== lastUpdateCard) {
              await localforage.setItem('lastupdate', lastUpdateCard);
              await localforage.removeItem('lastOrder');
              window.localStorage.removeItem('baskets');
              if (card.start && card.lastupdate) {
                window.location.reload();
              }
            }
            setData(card);
          }
        },
        e => error('get_application_pwa_error', e),
      );
  }, []);
  return { pwa: data };
};
