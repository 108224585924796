import React from 'react';
import { get, map, orderBy } from 'lodash';
import styled from 'styled-components';
import { IonList } from '@ionic/react';
import { Formik, useFormikContext, Form } from 'formik';
import { colors } from 'kards-components';
import { useHistory, Redirect } from 'react-router-dom';
import { useTheme } from 'src/modules/card/context/theme';
import { useReadContext } from 'src/modules/card/context';
import { useTimeOrder } from 'src/data/hooks/getOrderInPreparationNumbers';
import { useBasketContext, BasketsRecap } from 'src/modules/basket';
import { Footer, Content, PaymentMethodField } from 'src/common-ui';
import { ButtonDivideBill } from 'src/modules/divide-bill';
import { PreorderSlotsField } from './fields/PreorderSlotsField';
import { DeleveryField } from './fields/DeleveryField';
import { LastNameField } from './fields/LastNameField';
import { FirstNameField } from './fields/FirstNameField';
import { EstimatedPreparationTime } from './EstimatedPreparationTime';
import { PreOrderTitle } from './PreOrderTitle';
import { useOnFireContext } from 'src/modules/onFire';

function validate({ values, mode }) {
  let errors = {};
  if (!values.delivery) {
    errors = { error: true };
  }
  if (values.delivery === 'onplace' && !values.onplace_number) {
    errors = { error: true };
  }
  if (!values.payment) {
    errors = { error: true };
  }
  if (mode === 'preorder' && (!values.userInformations.name || !values.userInformations.firstname)) {
    errors = { error: true };
  }
  if (mode === 'preorder' && !values.preorderSlots) {
    errors = { error: true };
  }
  return errors;
}

function FormWithHook({ deliveryActive }) {
  const { values, errors } = useFormikContext();
  const theme = useTheme();
  const { deliveryInstruction, deliveryInstructionAdresse, averageTime, day, service, mode, onFire }: any = useReadContext();
  const { data: dataAverageTime } = useTimeOrder({ averageTime, initialTime: Date.now() });
  const { setOpenModal } = useOnFireContext();

  return (
    <Content fullscreen data-cy="confirm-screen">
      <Form>
        <BasketsRecap />
        <List style={{ marginBottom: '10em' }} color={theme?.color.background} lines="none">
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {mode === 'preorder' ? (
              <PreOrderTitle label="Votre commande pour :">{`${day.label} - ${service.label}`}</PreOrderTitle>
            ) : (
              <EstimatedPreparationTime label="Estimation du temps de préparation">{`${dataAverageTime} min`}</EstimatedPreparationTime>
            )}
          </div>
          {mode === 'preorder' && (
            <>
              <FirstNameField name="userInformations.firstname" />
              <LastNameField name="userInformations.name" />
            </>
          )}
          <DeleveryField
            name="delivery"
            deliveryInstruction={deliveryInstruction}
            deliveryInstructionAdresse={deliveryInstructionAdresse}
            deliveryActive={deliveryActive}
          />
          <PaymentMethodField name="payment" />
          {mode === 'preorder' && <PreorderSlotsField name="preorderSlots" dataAverageTime={dataAverageTime} />}
          {/* FEATURE SAAS */}
          {/* <ButtonDivideBill
            values={values}
            toDivide={{ pathname: '/bill/divide/', state: values }}
            disabled={onFire || Object.keys(validate({ values, mode })).length}
          /> */}
        </List>
        {onFire ? (
          <Footer
            title="Dans le jus"
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}
            data-cy="btn-order-price"
            displayNumber
            displayPrice
          />
        ) : (
          <Footer
            disabled={Object.keys(errors).length > 0}
            title="Confirmer"
            type="submit"
            data-cy="btn-order-price"
            displayNumber
            displayPrice
          />
        )}
      </Form>
    </Content>
  );
}

export default function Confirm() {
  const history = useHistory();
  const { delivery, payment_mode, userInformations, setUserInformations, mode }: any = useReadContext();
  const { nbProducts } = useBasketContext();

  const paymentActive = getPaymentActive(payment_mode);
  const deliveryActive = getDeliveryActive(delivery);

  if (nbProducts <= 0) return <Redirect to="/" />;

  return (
    <Formik
      validateOnMount
      initialValues={{
        delivery: get(deliveryActive, '0.value'),
        payment: get(paymentActive, '0.value'),
        userInformations,
      }}
      validate={values => validate({ values, mode })}
      onSubmit={values => {
        if (values?.userInformations) {
          setUserInformations(values.userInformations);
        }
        return history.push('/orders/card/paid', values);
      }}
    >
      <FormWithHook deliveryActive={deliveryActive} />
    </Formik>
  );
}

const List = styled(IonList)`
  background-color: ${props => colors[props.color]};
  margin: 1.5em;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

function getPaymentActive(payment_mode) {
  const paymentTypes = {
    card: {
      label: 'Carte de crédit',
      value: 'card',
      icon: '/img/icon/creditCard.svg',
      checked: true,
    },
    cash: {
      label: 'En espèces',
      value: 'cash',
      icon: '/img/icon/cash.svg',
    },
    lydia: {
      label: 'Lydia',
      value: 'lydia',
      icon: '/img/icon/lydia.svg',
    },
  };
  return map(payment_mode, (v, k) => (v === true ? paymentTypes[k] : null)).filter(v => v != null);
}

function getDeliveryActive(delivery) {
  const delivreyTypes = {
    onplace: {
      label: 'À table',
      checked: true,
      value: 'onplace',
      wrapper: {
        place: {
          value: null,
          label: 'N°',
        },
      },
      icon: '/img/icon/waiter.svg',
    },
    delivered: {
      checked: true,
      label: 'Point de retrait',
      value: 'delivered',
      icon: '/img/icon/location.svg',
    },
  };

  return orderBy(
    map(delivery, (v, k) => (v === true ? delivreyTypes[k] : null)).filter(v => v != null),
    'label',
  );
}
