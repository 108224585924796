import React from 'react';
import { IonButton, IonContent } from '@ionic/react';
import { get } from 'lodash';
import styled from 'styled-components';
import { colors } from 'kards-components';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import { useTheme } from '../../card/context/theme';
import { useReadContext } from '../../card/context';

const BoxWrapper = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  display: flex;
  overflow: scroll;
  background: ${props => props.background};
  flex: 1;
  :focus {
    outline-width: 0;
  }
`;

const IonContentWrapper = styled(IonContent)`
  background-color: ${props => colors[props.color]};
`;

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.textDecoration};
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
`;

export const questions = {
  1: 'Je souhaite contacter le support de Kards.',
  2: 'Je ne retrouve pas la commande que je viens de passer.',
  3: 'Ma commande met plus de temps que l’estimation à être préparée.',
  4: 'Je ne comprend pas pourquoi je dois rentrer mon téléphone pour payer.',
};

const HelpDetail = () => {
  const theme = useTheme();
  const { user }: any = useReadContext();
  const history = useHistory();
  const { id } = useParams();

  const response = {
    1: 'Nous vous invitons à vous rendre dans la rubrique «nous contacter» dans le menu et à bien suivre les indications.',
    2: `Pas de soucis ! Vérifiez que votre téléphone n’est pas en mode navigation privée,
        cliquez ensuite sur le bouton «Retrouver ma commande» ci-dessous.
        Si votre commande n’apparaît toujours pas, nous vous invitons à contacter notre support
        en n'oubliant pas de renseigner le numéro de téléphone raccroché à votre commande.`,
    3: `Pas d’inquiétude, votre commande va arriver.
        L’estimation de temps est là pour vous aider à aller
        chercher votre commande au bon moment.
        Nos équipes ont à cœur d’essayer de respecter
        ces délais au mieux et de vous satisfaire chaque jour.`,
    4: `Votre numéro de téléphone vous permet de renforcer la sécurité de votre paiement,
        d’être prévenu par sms dès lors que votre commande est prête et de ne pas perdre la trace de votre commande en cours.
        Le tout, sans avoir à télécharger d'application et en respectant notre politique de confidentialité.`,
  };

  return (
    <IonContentWrapper color={get(theme, 'color.background')} lines="none">
      <BoxWrapper>
        <Text color={colors[get(theme, 'color.title')]} textAlign="center" fontSize="1.5em" fontWeight="400" padding="8vw">
          {questions[id]}
        </Text>
        <Text color={colors[get(theme, 'color.title')]} paddingBottom="2em" fontSize="1em" fontWeight="200" padding="0 10vw">
          {response[id]}
        </Text>
        {id === '2' && !user && (
          <IonButton
            color={get(theme, 'color.title')}
            size="small"
            style={{
              marginBottom: '1em',
              fontSize: '1em',
              '--border-radius': '30px',
            }}
            onClick={() => history.push('/auth')}
          >
            Retrouver ma commande
          </IonButton>
        )}
      </BoxWrapper>
    </IonContentWrapper>
  );
};

export default HelpDetail;
