import React, { useState, useEffect } from 'react';
import { groupBy, sortBy, flatMap } from 'lodash';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import firebase from 'firebase/app';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Fade from 'react-reveal/Fade';

import { Text, PaymentMethodField, Footer } from 'src/common-ui';
import { getOrderById, getUserOrderByName, addUserNameToOrder } from 'src/data/accessor/api';
import { useQuery } from 'src/data/hooks/useQuery';
import { Basket } from 'src/data/types';
import { useLocalStorage } from 'src/data/hooks/useLocalStorage';
import { Product } from '../components/Product';
import { Chip } from '../components/Chip';
import { useTheme } from 'src/modules/card/context/theme';
import { FingerCross } from '../components/Logos';
import { toEuro, sumTotal } from '../utils';
import { FormikLayout } from '../components/FormikLayout';

type Product = Basket & { idIndex: string };
type ProductBilling = Product & { selected: boolean; name: string; payed?: boolean };

export function ShareBillPaymentChoice() {
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { error, data: order } = useQuery(() => getOrderById(id));
  const { items: baskets, totalPrice } = order || {};
  const idsFromFirebase = useSelectedProductOnSnapshot(id);
  const [name, setName] = useLocalStorage<string>('firsname', local => local || null);
  const [show, setShow] = useState(Boolean(name));
  const [userExist, setUserAlreadyExist] = useState(false);

  const flatBaskets = getFlatBaskets(baskets);
  const selectedItems = idsFromFirebase.filter(t => t.selected);
  const selectedProductByName = groupBy(selectedItems, 'name');
  const myProducts = selectedItems.filter(d => d.name === name).filter(d => !d.payed);
  const friendsProducts = selectedItems.filter(d => d.name !== name);
  const amountToPay = sumTotal(myProducts.map(p => p.price).filter(Boolean))?.getAmount();

  const isSelectedByFriend = (product: Product) => Boolean(friendsProducts.find(p => p.idIndex === product.idIndex));
  const isPayed = (product: Product) => idsFromFirebase.find(p => p.idIndex === product.idIndex)?.payed;

  const saveName = async () => {
    const user = await getUserOrderByName(id, name);
    if (user) {
      setUserAlreadyExist(true);
    } else {
      await addUserNameToOrder(id, name);
      setShow(true);
    }
  };

  const submit = async values => {
    if (order?.status?.S3 || order?.status?.S4 || order?.status?.S5) {
      history.push({ pathname: `/bill/share/payment-status/${id}` });
    } else {
      const state = {
        ...values,
        productIds: myProducts.map(p => p.id),
        productsBillingsIds: myProducts.map(p => p.idIndex),
        amountToPay,
        name,
      };
      history.push({ pathname: `/bill/share/payment/${id}`, state });
    }
  };

  const handleChange = (product: Product) => (selected: boolean) => {
    firebase
      .app()
      .firestore()
      .collection('orders')
      .doc(id)
      .collection('products-billing')
      .doc(product.idIndex)
      .set({
        selected,
        name,
        id: product.id,
        idIndex: product.idIndex,
        reference: product.reference,
        price: product.price,
      });
  };

  const handleDelete = (product: Product) => {
    firebase
      .app()
      .firestore()
      .collection('orders')
      .doc(id)
      .collection('products-billing')
      .doc(product.idIndex)
      .set({ selected: false });
  };

  if (error) return <Redirect to="/bill/share/" />;

  return (
    <FormikLayout initialValues={{ payment: 'card' }} onSubmit={submit} style={{ paddingBottom: '100px' }}>
      <FingerCross width="40%" />
      <Text fontWeigth="bold" fontSize="22px" margin="10px" textAlign="center">
        Payer ma part
      </Text>

      <Text width="100%" justifyContent="center">{`Total : ${toEuro(totalPrice)}`}</Text>

      <div style={{ width: '100%', padding: '10px' }}>
        <Fade when={show}>
          <Text fontSize="24px" fontWeight="bold" marginLeft="10px">
            {name}
          </Text>
        </Fade>
        <Fade when={!show} collapse>
          <IonItem color={theme?.color?.background} lines="none">
            <IonLabel
              color={theme?.color?.title}
              position="floating"
              style={{ fontWeight: 'bold', fontSize: '21px', marginBottom: '20px' }}
            >
              Votre Prénom
            </IonLabel>
            <div style={{ display: 'flex', width: '100%', fontSize: '18px' }}>
              <IonInput
                color={theme?.color?.title}
                type="text"
                value={name}
                placeholder="Entrez votre nom"
                data-cy="input-firstname"
                onIonChange={e => {
                  setName(e.detail.value);
                }}
              />
              <IonButton onClick={saveName} disabled={!name || name?.length < 3} data-cy="btn-valider">
                Valider
              </IonButton>
            </div>
            <Text fontSize="12px" marginTop="20px">
              Attention, vous ne pourrez pas mettre 2 fois le même prénom
            </Text>
            {userExist && (
              <Text marginTop="20px" color="danger">
                Ce prénom est déjà pris parmi vos amis. Choisissez en un autre
              </Text>
            )}
          </IonItem>
        </Fade>
      </div>

      <div style={{ width: '100%' }}>
        <Fade bottom collapse cascade when={show}>
          <div style={{ width: '100%', paddingBottom: '100px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', backgroundColor: 'none' }}>
              <Text
                fontWeigth="bold"
                width="100%"
                justifyContent="flex-start"
                marginLeft="20px"
                paddingBottom=".2em"
                paddingTop="1em"
                data-cy="title-products"
              >
                Sélectionnez votre part :
              </Text>
              <IonList color={theme?.color?.background} style={{ paddingBottom: 0, paddingTop: 0 }} data-cy="products">
                {flatBaskets.map((product, index) => (
                  <Product
                    key={product.idIndex}
                    product={product}
                    checked={Boolean(myProducts.find(p => p.idIndex === product.idIndex))}
                    disabled={isSelectedByFriend(product) || isPayed(product)}
                    onChange={handleChange(product)}
                    data-cy={`product-${index}`}
                  />
                ))}
              </IonList>
            </div>

            <div style={{ width: '100%', padding: '0 10px', marginTop: '1em' }}>
              {sortBy(Object.entries(selectedProductByName), p => p[0], 'asc').map(([userName, products]) => (
                <div key={userName}>
                  <Text marginLeft="12px" fontWeight="bold">{`${userName} :`}</Text>

                  <TransitionGroup exit={true} enter={true} appear={false} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {sortBy(products, 'reference', 'asc').map(product => (
                      <Fade key={product.idIndex}>
                        <Chip key={product.idIndex} product={product} onDelete={() => handleDelete(product)} />
                      </Fade>
                    ))}
                  </TransitionGroup>
                </div>
              ))}
            </div>

            <div style={{ width: '100%' }}>
              <PaymentMethodField name="payment" paddingTop="2em" />
            </div>
          </div>
        </Fade>
      </div>
      {show && (
        <Footer
          type="submit"
          disabled={!myProducts.length && !(order?.status?.S3 || order?.status?.S4 || order?.status?.S5)}
          nbProducts={myProducts.length}
          displayNumber
          displayPrice
          title="Valider"
          price={toEuro(amountToPay)}
          data-cy="valider"
        />
      )}
    </FormikLayout>
  );
}

function useSelectedProductOnSnapshot(orderId: string) {
  const [idsFromFirebase, setIdsFromFirebase] = useState<ProductBilling[]>([]);

  useEffect(() => {
    firebase
      .app()
      .firestore()
      .collection('orders')
      .doc(orderId)
      .collection('products-billing')
      .onSnapshot(docs => {
        const results = [];
        docs.forEach(doc => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setIdsFromFirebase(results);
      });
  }, []);

  return idsFromFirebase;
}

function getFlatBaskets(baskets: Basket[] = []) {
  return flatMap(baskets, b => {
    if (b.quantity > 1) {
      return Array.from({ length: b.quantity }, (d, i) => ({ ...b, quantity: 1, idIndex: b.id + '-' + i }));
    }
    return { ...b, idIndex: b.id };
  });
}
