import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { Order } from '../types';

export function useWatchOrder({ id }): Order {
  const [data, setOrder] = useState(undefined as Order);

  useEffect(() => {
    if (!id) return;

    const defaultFirestore = firebase.app();
    return defaultFirestore
      .firestore()
      .collection('orders')
      .doc(id)
      .onSnapshot(
        {},
        doc => {
          const order: any = doc.data();
          if (order) setOrder(order);
        },
        e => console.log('error', e),
      );
  }, [id]);

  return data;
}
