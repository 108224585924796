import React from 'react';
import { Image } from 'kards-components';
import { useTheme } from 'src/modules/card/context/theme';

function ImageComponent({ component }) {
  const data = component.value
  const theme = useTheme();

  return (
    <Image
      theme={theme}
      title={data?.title}
      description={data?.description}
      picture={data?.picture}
      link={data?.link}
      full={data?.full}
    />
  );
}
export default React.memo(ImageComponent, () => true);
