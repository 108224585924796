import React from 'react';
import { Offer } from 'kards-components';

import { get } from 'lodash';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';

import { Link } from 'react-router-dom';
import { useTheme } from 'src/modules/card/context/theme';
import { useBasketContext } from 'src/modules/basket';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';

const LinkWithCheck = ({ data, to, ...props }) => {
  const { isOffertDisponible } = useBasketContext();
  return isOffertDisponible({ ...data, type: 'offer' }) ? <Link to={to} {...props} /> : <span {...props} />;
};

export default ({ component }) => {
  const data = component.value
  const theme = useTheme();
  const { isOffertDisponible } = useBasketContext();
  const { trackAddProductToBasket } = useAnalyticsContext()

  return (
    <LinkWithCheck
      key={get(data, 'id')}
      style={{ textDecoration: 'none' }}
      data={data}
      to={{
        pathname: '/offer',
        state: { offer: data },
      }}
    >
      <Offer
        theme={theme}
        name={get(data, 'reference')}
        stock={{ unlimited: isOffertDisponible(data) }}
        picture={get(data, 'picture')}
        id={get(data, 'id')}
        description={get(data, 'description')}
        settingFields={get(data, 'settingFields')}
        price={get(data, 'price')}
        tva={get(data, 'tva')}
        key={get(data, 'id')}
        width="100%"
        read
      >
        <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })}/>
      </Offer>
    </LinkWithCheck>
  );
};
