import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { IonButton, IonIcon, IonHeader, IonTitle } from '@ionic/react';
import { arrowBack, menu } from 'ionicons/icons';
import { templateType, colors } from 'kards-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';
import { ReactComponent as Logo } from 'src/svg/iconeNoir.svg';

const WrapperHeader = styled.div`
  background-color: ${props => props.color};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IonButtonWrapper = styled(IonButton)`
  margin: 0;
`;

const IonHeaderWrapper = styled(IonHeader)`
  background-color: ${props => props.color};
  position: relative;
  margin-bottom: -1px;
  &::after {
    height: 0;
  }
`;

const IonTitleWrapper = styled(IonTitle)`
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-bottom: 0;
  transition: all 800ms;
`;

const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0.6;
  padding-top: ${props => props.paddingTop || 0}px;
  padding-bottom: ${props => props.paddingBottom || 0}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.2;
`;

const LogoSvg = styled(Logo)`
  width: 1em;
  fill: ${props => props.color} !important;
  .st0 {
    fill: ${props => props.color} !important;
  }
`;

const Header = ({ template }) => {
  const { setCurrentCategory, currentCategory, noOrder }: any = useReadContext();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();

  return (
    <IonHeaderWrapper color={colors[get(theme, 'color.backgroundHeader')]} id="main-content">
      <WrapperHeader color={colors[get(theme, 'color.backgroundHeader')]}>
        <IonTitleWrapper size="small" currentCategory={currentCategory}>
          <ContainerTitle>
            <FlexWrapper>
              {(location.pathname.includes('/product') ||
                location.pathname.includes('/offer') ||
                location.pathname.includes('/bill') ||
                ((template === templateType.GalleryInline || template === templateType.Gallery) && currentCategory)) && (
                <IonButtonWrapper
                  fill="clear"
                  onClick={() => {
                    if (location.pathname.includes('/product') 
                    || location.pathname.includes('/offer')
                    || location.pathname.includes('/bill')
                    ) {
                      history.goBack();
                      return;
                    }
                    setCurrentCategory(null);
                  }}
                >
                  <IonIcon slot="icon-only" icon={arrowBack} color={get(theme, 'color.buttonHeaderColor')} />
                </IonButtonWrapper>
              )}
            </FlexWrapper>
            <ContainerText paddingTop={get(theme, 'header.logoPaddingTop')} paddingBottom={get(theme, 'header.logoPaddingBottom')}>
              <img height={get(theme, 'header.logoSizeHeight')} src={get(theme, 'header.logo')} alt="header" />
            </ContainerText>
            <FlexWrapper>
              {noOrder ? (
                <LogoSvg color={colors[get(theme, 'color.title')]} />
              ) : (
                <IonButtonWrapper data-cy="burger-menu" fill="clear" onClick={() => history.push('/menu')}>
                  <IonIcon slot="icon-only" icon={menu} color={get(theme, 'color.buttonHeaderColor')} />
                </IonButtonWrapper>
              )}
            </FlexWrapper>
          </ContainerTitle>
        </IonTitleWrapper>
      </WrapperHeader>
    </IonHeaderWrapper>
  );
};

export default Header;
