import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { templateType } from 'kards-components';
import { Footer } from 'src/common-ui';
import { useReadContext } from './context';
import { getTemplate } from './kardTemplate/getTemplate';
import { useBasketContext } from 'src/modules/basket';
import { useOrderPreparingContext } from 'src/modules/liveOrder';
import { ButtonSameOrder } from './ButtonSameOrder';

function CardRead() {
  const { lastOrderId } = useOrderPreparingContext();
  const { setCurrentCategory, currentCategory, cardData, cardTemplate, templateName }: any = useReadContext();
  const { nbProducts } = useBasketContext();
  const history = useHistory();

  useEffect(() => {
    if (
      !currentCategory &&
      (get(cardTemplate, 'name') === templateType.Top || (get(cardTemplate, 'name') === templateType.TopWithText && !currentCategory))
    ) {
      setCurrentCategory(get(cardData, '0.id'));
    }
  }, [cardData]);

  return (
    <>
      {getTemplate(templateName)}
      {lastOrderId && <ButtonSameOrder />}
      <Footer
        title="Commander"
        disabled={nbProducts <= 0}
        data-cy="btn-order-card"
        displayNumber
        displayPrice
        onClick={() => history.push('/orders/confirm')}
      />
    </>
  );
}

export default React.memo(CardRead);
