import { useEffect, useState } from 'react';

import { map } from 'lodash';
import { listUserCreditCards } from '../accessor/stripe';
import { useReadContext } from '../../modules/card/context/index';
import { getOrdersByUserIds } from '../accessor/api';

export const useCreditCards = () => {
  const { user }: any = useReadContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const listCard = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      const orders = await getOrdersByUserIds([user?.uid]);
      console.log('Log: listCard -> orders', orders)

      if (!(orders && orders.length > 0)) {
        setLoading(false);
        return;
      }

      const { data }: any = await listUserCreditCards();
      const cards = map(data?.data, creditCard => {
        return {
          ...creditCard.card,
          payment_method_id: creditCard.id,
          name: creditCard.metadata.name,
        };
      });
      setData(cards);
      setLoading(false);
    };

    listCard();
  }, []);

  return { data, loadingCreditCard: loading, setData };
};
