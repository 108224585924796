import React from 'react';
import CarrouselOrSimpleList from '../CarouselOrSimpleList';
import { useMostPopularProduct } from 'src/data/accessor/getMostPopular';

export default function MostPopular({ component }) {
  const data = component.value
  const mostPopularProducts = useMostPopularProduct();
  const title = data.title || 'Les plus populaires 🔥';

  if (!mostPopularProducts?.length) return null;

  return (
    <CarrouselOrSimpleList component={component} data={{ id: data.id, products: mostPopularProducts, title, description: data.description }} icon={<>🔥</>} />
  );
}
