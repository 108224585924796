import React from 'react';
import { get, map } from 'lodash';
import { IonItemGroup, IonItemDivider, IonItem, IonLabel } from '@ionic/react';
import { Field, Form as FormikForm } from 'formik';
import { colors } from 'kards-components';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveProductUI } from 'src/modules/basket';
import { Footer } from 'src/common-ui';
import { GetFieldByType } from './Field';
import { useReadContext } from 'src/modules/card/context';
import { useBasketContext } from 'src/modules/basket';
import { getRandColors } from 'src/helpers/colors';
import styled from 'styled-components';

const ContainerPicture = styled.div`
  background-image: url(${props=>props.picture});
  background-size: cover;
  background-color: ${props=>props.backgroundColor};
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const colorsBackground = getRandColors();

export function Form({ displayQuantity = true, entity, errors, SettingsFieldComponent = null, isOffer = false }) {
  const { noOrder }: any = useReadContext();
  const theme = useTheme();
  const { nbProducts, totalPrice } = useBasketContext();
  return (
    <FormikForm style={{ overflow: 'scroll', paddingBottom: '120px' }}>
      <ContainerPicture
        picture={entity.picture}
        backgroundColor={!entity?.picture ? colorsBackground:null}
      >
        <p
          style={{
            padding: '10px',
            color: 'white',
            fontSize: '1.8em',
            backgroundColor: 'rgba(200,200,200,0.5)',
            textAlign: 'center',
          }}
        >
          {entity.reference}
        </p>
      </ContainerPicture>
      <p
        style={{
          background: colors[get(theme, 'color.background')],
          padding: '10px',
          fontWeight: 'bold',
          margin: 0,
          color: get(theme, 'color.productTitle'),
        }}
      >
        {entity.description}
      </p>
      <hr style={{ margin: '0px' }} />
      <IonItemGroup data-cy="list-items-options">
        {map(entity.settingFields, settingField => (
          <SettingsFieldComponent key={settingField.label} settingField={settingField} />
        ))}
        <div style={{ background: colors[get(theme, 'color.background')] }}>{!noOrder && displayQuantity && <QuantityField />}</div>
      </IonItemGroup>
      <Footer
        type="submit"
        style={{ position: isOffer ? 'fixed' : 'absolute' }}
        title="Valider"
        disabled={Object.keys(errors).length > 0}
        nbProducts={nbProducts}
        price={totalPrice}
      />
    </FormikForm>
  );
}

export function QuantityField() {
  const theme = useTheme();
  return (
    <Field name="quantity">
      {({ field, form }) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <IonItemDivider mode="md" color={get(theme, 'color.background')}>
            <IonLabel style={{ paddingLeft: '16px' }}> Combien en voulez vous ?</IonLabel>
          </IonItemDivider>
          <AddRemoveProductUI
            onClickLess={() => form.setFieldValue(field.name, field.value - 1)}
            onClickMore={() => form.setFieldValue(field.name, field.value + 1)}
            disabledLess={field.value === 0}
          >
            {field.value}{' '}
          </AddRemoveProductUI>
        </div>
      )}
    </Field>
  );
}

export function SettingField({ settingField }) {
  const theme = useTheme();
  return (
    <div style={{ background: colors[get(theme, 'color.background')], padding: '1em', width: '100%' }}>
      <IonItemDivider mode="md" color={get(theme, 'color.background')}>
        <IonLabel>{settingField.label}</IonLabel>
      </IonItemDivider>
      <Field name={`options.${settingField.label}`}>
        {({ field, form, meta }) => (
          <IonItem lines="none" color={get(theme, 'color.background')}>
            <GetFieldByType
              onChange={value => {
                form.setFieldValue(field.name, value);
              }}
              error={meta.error}
              value={field.value}
              type={settingField.type}
              options={settingField.options}
              optionsNumber={settingField.optionsNumber}
            />
          </IonItem>
        )}
      </Field>
    </div>
  );
}







