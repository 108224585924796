import React, { createContext, useState, useEffect, useContext } from 'react';
import { cloneDeep } from 'lodash';
import firebase from 'firebase';
import localforage from 'localforage';
import { getComponentAvailable } from './getComponentAvailable';
import { eventNames } from 'src/analytics/eventNames';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const daysFR = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const dayAfter = () => {
  if (new Date().getDay() + 1 === 7) return 0;
  return new Date().getDay() + 1;
};

const CardContext = createContext({});

export function useReadContext() {
  return useContext(CardContext);
}

export function CardProvider({ children, apiData, averageTime, open, start, onFire }) {
  const [currentCategory, setCurrentCategory] = useState();
  const [userInformations, setUserInformations] = useState({ name: null, firstname: null });

  const [modalData, openProductPageWithProduct] = useState({
    open: false,
    data: null,
  });

  const manageCard = useManageCard({ apiData, open });
  const userInfos = useUserInfo();
  const { payment_mode, delivery, deliveryInstruction, deliveryInstructionAdresse } = apiData || {};

  const value = {
    ...userInfos,
    ...manageCard,

    payment_mode,
    paymentMode: payment_mode,
    delivery,
    deliveryInstruction,
    deliveryInstructionAdresse,
    templateName: apiData?.template?.name,
    open,
    averageTime,
    start,
    onFire,

    currentCategory,
    setCurrentCategory,

    modalData,
    openProductPageWithProduct,

    userInformations,
    setUserInformations,
  };

  return <CardContext.Provider value={value}>{children}</CardContext.Provider>;
}

function useUserInfo() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const { logEvent } = useAnalyticsContext()

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      setLoadingUser(false);
    });
    logEvent({ type: eventNames.openApplication})
    firebase.analytics().logEvent('open_application');
  }, []);

  return {
    user,
    loadingUser,
    setUser,
  };
}

function useManageCard({ apiData, open }) {
  const [service, setService] = useState({ label: 'Midi', value: 'midday' });
  const [day, setDay] = useState({
    label: daysFR[dayAfter()],
    value: days[dayAfter()],
    key: dayAfter(),
  });

  const mode = apiData?.order_mode === 'noOrder' ? 'instant' : apiData.order_mode;
  const noOrder = apiData?.order_mode === 'noOrder';

  const componentsAvailable = React.useMemo(
    () => getComponentAvailable({ categories: cloneDeep(apiData[mode]), mode, day, service, open }),
    [apiData, day, service],
  );

  return {
    categoriesProducts: apiData[mode],
    cardData: componentsAvailable || [],
    cardTemplate: apiData.template,
    mode,
    noOrder,
    day,
    service,
    setDay: async d => {
      setDay(d);
    },
    setService: async s => {
      setService(s);
      await localforage.setItem('service', s);
    },
  };
}
