import React from 'react';
import { SimpleList } from 'kards-components';

import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';

export default ({ component }) => {
  const data = component.value
  const theme = useTheme();
  const { trackAddProductToBasket } = useAnalyticsContext()

  return (
    <SimpleList theme={theme} key={data.id} data={data}>
      <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })}/>
    </SimpleList>
  );
};
