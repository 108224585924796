import React from 'react';
import { IonButton, IonModal, IonText } from '@ionic/react';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';
import styled from 'styled-components';
import get from 'lodash/get';
import { colors } from 'kards-components';

const mapDayOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const dayOptions = [
  {
    label: 'Dimanche',
    value: 'sunday',
    key: 0,
  },
  {
    label: 'Lundi',
    value: 'monday',
    key: 1,
  },
  {
    label: 'Mardi',
    value: 'tuesday',
    key: 2,
  },
  {
    label: 'Mercredi',
    value: 'wednesday',
    key: 3,
  },
  {
    label: 'Jeudi',
    value: 'thursday',
    key: 4,
  },
  {
    label: 'Vendredi',
    value: 'friday',
    key: 5,
  },
  {
    label: 'Samedi',
    value: 'saturday',
    key: 6,
  },
];

const serviceOptions = [
  {
    label: 'Midi',
    value: 'midday',
  },
  {
    label: 'Soir',
    value: 'night',
  },
];

function getFilteredDayOptions() {
  return dayOptions.map(obj =>
    obj.key === new Date().getDay()
      ? {
          label: "Aujourd'hui",
          value: mapDayOfWeek[new Date().getDay()],
          key: new Date().getDay(),
        }
      : obj,
  );
}

export function ModalPreOrder({ isOpen, closeModal }) {
  const theme = useTheme();
  const { service, setService, day, setDay }: any = useReadContext();
  const filteredDayOptions = getFilteredDayOptions();

  return (
    <IonModalWrapper isOpen={isOpen} swipeToClose showBackdrop background={colors[theme?.color.background]} onDidDismiss={closeModal}>
      <ContentModal>
        <Text display="flex" fontSize="1.1em" fontWeight="bold" color={colors[get(theme, 'color.title')]} paddingBottom=".5em">
          Choisissez votre jour
        </Text>
        <ContainerButton>
          {filteredDayOptions.map(d => (
            <Button
              key={d.value}
              size="small"
              background={d.value === day.value ? colors[get(theme, 'color.title')] : 'rgba(100,100,100,0.3)'}
              onClick={() => {
                setDay(d);
              }}
            >
              <IonText color={d.value === day.value ? get(theme, 'color.background') : get(theme, 'color.title')}>{d.label}</IonText>
            </Button>
          ))}
        </ContainerButton>
        <Text display="flex" fontSize="1.1em" fontWeight="bold" color={colors[get(theme, 'color.title')]} paddingBottom=".5em">
          Choisissez votre créneau
        </Text>
        <ContainerButton>
          {serviceOptions.map(d => (
            <Button
              key={d.value}
              size="small"
              background={d.value === service.value ? colors[get(theme, 'color.title')] : 'rgba(100,100,100,0.3)'}
              onClick={() => {
                setService(d);
              }}
            >
              <IonText color={d.value === service.value ? get(theme, 'color.background') : get(theme, 'color.title')}>{d.label}</IonText>
            </Button>
          ))}
        </ContainerButton>
        <ContainerClose color={get(theme, 'color.title')}>
          <Button data-cy="btn-close-selectMode" size="small" color={get(theme, 'color.title')} onClick={closeModal}>
            Fermer
          </Button>
        </ContainerClose>
      </ContentModal>
      );
    </IonModalWrapper>
  );
}

const Button = styled(IonButton)`
  --background: ${props => props.background};
  --box-shadow: none;
  --background-activated: white;
  margin: 0.4em;
`;

const ContentModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const IonModalWrapper = styled(IonModal)`
  align-items: flex-end;
  --background: ${props => props.background} .modal-wrapper {
    height: 65vh;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1em;
  width: 100%;
`;

const ContainerClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  width: 100%;
  border-top: solid 1px ${props => colors[props.color]};
`;

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.textDecoration};
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
`;
