import React from 'react';
import { IonItem, IonLabel, IonInput } from '@ionic/react';
import { useTheme } from 'src/modules/card/context/theme';
import { useLocalStorage } from 'src/data/hooks/useLocalStorage';
import { Content } from 'src/common-ui';

export default function Profile() {
  const theme = useTheme();
  const [firsname, setFirstname] = useLocalStorage<string>('firsname', local => local || null);
  const [lastname, setLastname] = useLocalStorage<string>('lastname', local => local || null);
  const [age, setAge] = useLocalStorage<number>('age', local => local || null);

  return (
    <Content fullscreen>
      <IonItem color={theme?.color?.background} lines="none">
        <IonLabel color={theme?.color?.title} position="floating" style={{ fontWeight: 'bold', fontSize: '21px', marginBottom: '20px' }}>
          Votre Prénom
        </IonLabel>
        <div style={{ display: 'flex', width: '100%', fontSize: '18px' }}>
          <IonInput
            color={theme?.color?.title}
            type="text"
            value={firsname}
            placeholder="Entrez votre prénom"
            onIonChange={e => {
              setFirstname(e.detail.value);
            }}
          />
        </div>
      </IonItem>
      <IonItem color={theme?.color?.background} lines="none">
        <IonLabel color={theme?.color?.title} position="floating" style={{ fontWeight: 'bold', fontSize: '21px', marginBottom: '20px' }}>
          Votre nom
        </IonLabel>
        <div style={{ display: 'flex', width: '100%', fontSize: '18px' }}>
          <IonInput
            color={theme?.color?.title}
            type="text"
            value={lastname}
            placeholder="Entrez votre nom"
            onIonChange={e => {
              setLastname(e.detail.value);
            }}
          />
        </div>
      </IonItem>
      <IonItem color={theme?.color?.background} lines="none">
        <IonLabel color={theme?.color?.title} position="floating" style={{ fontWeight: 'bold', fontSize: '21px', marginBottom: '20px' }}>
          Votre age
        </IonLabel>
        <div style={{ display: 'flex', width: '100%', fontSize: '18px' }}>
          <IonInput
            color={theme?.color?.title}
            type="number"
            value={age}
            placeholder="Entrez votre age"
            onIonChange={e => {
              setAge(Number(e.detail.value));
            }}
          />
        </div>
      </IonItem>
    </Content>
  );
}
