import React, { useState, createContext, useContext, useEffect } from 'react';

const context = createContext(null);

export function OnFireProvider({ onFire, children }) {
  const [isOpen, setOpenModal] = useState(true);

  useEffect(() => {
    setOpenModal(Boolean(onFire));
  }, [onFire]);

  return <context.Provider value={{ isOpen, setOpenModal }}>{children}</context.Provider>;
}

export function useOnFireContext() {
  return useContext(context);
}
