import Dinero from 'dinero.js';
import { v4 as uuid } from 'uuid';
import { cloneDeep, sum, groupBy, map, flatMap } from 'lodash';
import { Basket, Product } from 'src/data/types';

export function getProducts(componentsDisplays: any[] = []): Product[] {
  const products = flatMap(componentsDisplays, c => c.components)
    .filter(c => c.type === 'product' || c.type === 'offer')
    .map(c => ({ ...c.value, type: c.type }));

  const productsFromList = flatMap(componentsDisplays, c => c.components)
    .filter(c => c.type === 'simpleList' || c.type === 'carousel')
    .flatMap(c => c.value.products.map(p => ({ ...p, type: 'product' })));

  return [...products, ...productsFromList];
}

export function productToBasket(product): Basket {
  return {
    id: product.id,
    orderProductId: uuid(),
    reference: product.reference,
    description: product.description,
    price: parseInt(product.price, 10),
    quantity: 0,
    settingFields: cloneDeep(product.settingFields),
    stock: cloneDeep(product.stock),
    tva: product.tva,
    type: product.type,
    tag: product.tag,
  };
}

export function getTotalPrice(baskets: Basket[]): Dinero.Dinero {
  if (!baskets) {
    return Dinero({ amount: 0, currency: 'EUR' });
  }
  const line = baskets.map(item =>
    Dinero({ amount: item.price })
      .multiply(item.quantity)
      .getAmount(),
  );

  return sumTotal(line);
}

function sumTotal(tabAmount: number[]): Dinero.Dinero {
  let amount = Dinero({ amount: 0, currency: 'EUR' });
  tabAmount.forEach(a => {
    amount = amount.add(Dinero({ amount: a, currency: 'EUR' }));
  });
  return amount;
}

export function isOffertDisponible(basket: Product): boolean {
  if (basket?.type !== 'offer') return true;
  if (!basket?.settingFields) return true;

  const hasProducts = flatMap(basket.settingFields, s => s.products.length);
  if (hasProducts.some(l => l === 0)) return false;
  const tab = flatMap(basket.settingFields, setting => setting.products.map(p => ({ label: setting.label, ...p.stock })));
  const groupByLabel = groupBy(tab, 'label');
  const isOffertDisponibleByOptions = map(groupByLabel, value => value.some(v => v.unlimited || v.stockNumber > 0));
  const isDisponible = isOffertDisponibleByOptions.every(p => p);

  return isDisponible;
}

export function getNbProducts(pickedBaskets: Basket[]) {
  return sum(pickedBaskets.map(b => b.quantity));
}
