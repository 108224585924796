import { useEffect } from 'react';

export function useInterval(callback : () => void, trigger: any[], timeout = 1000) {
  useEffect(() => {
    const id = window.setInterval(() => {
      callback();
    }, timeout);

    return () => {
      clearInterval(id);
    };
  }, trigger);
}
