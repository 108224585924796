import firebase from 'firebase/app';
import { useState, useEffect } from 'react';
import { Payment, Order } from '../types';

// firebase
//   .app()
//   .functions()
//   .useFunctionsEmulator('http://localhost:5000');

export function useQuery<T>(apiClient: () => Promise<T>, trigger = []) {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    apiClient()
      .then(setData)
      .catch(err => setError(err))
      .then(() => setLoading(false));
  }, trigger);

  return { data, loading, error };
}

export function getOrderById(id: string) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .get();
}

export function useOrderById(orderId: string) {
  return useQuery(() => getOrderById(orderId).then(d => d.data() as Order));
}

export function getPaymentOrderById({ orderId, paymentId }) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .collection('payments')
    .doc(paymentId)
    .get()
    .then(res => res.data() as Payment);
}

export function dividePayment({ id, paymentMethod, payWithRestIfExist }) {
  const divide = firebase
    .app()
    .functions()
    .httpsCallable('dividePayment');

  return divide({ orderId: id, paymentMethod, payWithRestIfExist });
}

export function sharePayment({ orderId, paymentMethod, productIds, productsBillingsIds, name }) {
  const shareBill = firebase
    .app()
    .functions()
    .httpsCallable('sharePayment');

  return shareBill({ orderId, paymentMethod, productIds, productsBillingsIds, name });
}

export async function createOrderFunction(order: Partial<Order>) {
  const createOrder = firebase
    .app()
    .functions()
    .httpsCallable('createOrder');

  return createOrder(order);
}

export function updateOrder(orderId: string, order: Partial<Order>) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .update(order);
}

function getAllPaymentOrderFn(orderId: string) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .collection('payments')
    .where('amount', '>', 0);
}

export function getAllPaymentOrder(orderId: string) {
  return getAllPaymentOrderFn(orderId)
    .get()
    .then(querySnapshot => {
      const result = [];
      querySnapshot.forEach(doc => {
        result.push({ id: doc.id, ...doc.data() });
      });
      return result as (Payment & { id: string })[];
    });
}

export function usePaymentOnSnapshot(orderId: string) {
  const [payments, setPayments] = useState<Payment[]>();
  useEffect(() => {
    return getAllPaymentOrderFn(orderId).onSnapshot(querySnapshot => {
      const p = [];
      querySnapshot.forEach(doc => {
        p.push(doc.data());
      });
      setPayments(p);
    });
  }, []);

  return payments;
}

export function useGetOrderByIdOnSnapshot(id: string) {
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    return firebase
      .app()
      .firestore()
      .collection('orders')
      .doc(id)
      .onSnapshot(doc => {
        setLoading(true);
        setOrder(doc.data() as Order);
        setLoading(false);
      });
  }, []);

  return { loading, order };
}

export async function getUserOrderByName(id: string, name: string = '') {
  const doc = await firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .collection('users')
    .doc(name.toLocaleLowerCase())
    .get();
  return doc.data();
}

export function addUserNameToOrder(id: string, name: string = '') {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .collection('users')
    .doc(name.toLocaleLowerCase())
    .set({ name: name.toLocaleLowerCase() });
}

export async function getOrdersByUserIds(userIds: string[] = []) {
  console.log('Log: getOrdersByUserIds -> userIds', userIds)
  if (!userIds.length) return;
  const snapshot = await firebase
    .app()
    .firestore()
    .collection('orders')
    .where('userId', 'array-contains-any', userIds)
    .orderBy('created_at', 'desc')
    .get();
  const result: Order[] = [];
  snapshot.forEach(doc => {
    result.push(doc.data() as Order);
  });
  return result;
}

export function orderPrepared() {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .where('status.S3', '==', true)
    .orderBy('created_at', 'desc')
    .get()
    .then(data => data.docs.map(doc => doc.data()));
}
