import React from 'react';
import styled from 'styled-components';
import { S1, S2, S3, S4, S5 } from 'kards-components';
import { New, Preparing, Ready, Delivered } from './components/OrderingStatus';
import { useOrderPreparingContext } from './context';

const Screen = styled.div`
  height: 100vh;
  background-color: ${props => props.bgColor};
`;

export default function LiveOrder() {
  const { order, status } = useOrderPreparingContext();

  return (
    <Screen>
      {status === S1 && <New orderPreparing={order} />}
      {status === S2 && <Preparing orderPreparing={order} />}
      {status === S3 && <Preparing orderPreparing={order} />}
      {status === S4 && <Ready orderPreparing={order} deliveryMode={order?.parameters?.delivery} />}
      {status === S5 && <Delivered orderPreparing={order} />}
    </Screen>
  );
}
