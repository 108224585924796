import React from 'react';
import { CategoriesGalleryInline } from 'kards-components';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';

const GalleryList = ({ components }) => {
  const { cardData, setCurrentCategory, currentCategory }: any = useReadContext();
  const theme = useTheme();

  return (
    <>
      <CategoriesGalleryInline
        theme={theme}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        data={cardData}
        components={components}
      />
    </>
  );
};

export default GalleryList;
