import React from 'react';
import { useTheme } from 'src/modules/card/context/theme';
import { IonContent } from '@ionic/react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useQuery } from 'src/data/hooks/useQuery';
import { Text, ButtonLink, Footer } from 'src/common-ui';
import { useParams, useHistory } from 'react-router-dom';
import { getOrderById, usePaymentOnSnapshot } from '../../../data/accessor/api';
import { toEuro } from '../utils';
import { useSetOrderToPreparing } from '../components/useSetOrderToPreparaing';

export function ShareBillPaymentStatus() {
  const theme = useTheme();
  const history = useHistory();
  const { id: orderId } = useParams() as { id: string };

  const { data: order } = useQuery(() => getOrderById(orderId));
  const payments = usePaymentOnSnapshot(orderId);

  const numberOfFriends: number = payments?.length;
  const totalPrice: number = order?.totalPrice || 1;
  const totalPayed = payments ? payments.map(p => p.amount).reduce((a, b) => a + b, 0) : 0;
  const percentage = Math.round((totalPayed * 100) / totalPrice);

  useSetOrderToPreparing({ orderId, percentage });

  return (
    <IonContent fullscreen color={theme?.color?.background}>
      <div
        style={{
          padding: '0 30px 100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '40px',
            marginTop: '20px',
            height: '220px',
          }}
        >
          <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={1} />
        </div>

        <div style={{ border: '2px solid grey', padding: '10px 0px' }}>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
            Nombre de participant :<span style={{ fontWeight: 'bold', marginLeft: '5px' }}>{numberOfFriends}</span>
          </Text>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }} data-cy="totalPrice">
            Coût total :<span style={{ fontWeight: 'bold', marginLeft: '5px' }}>{toEuro(totalPrice)}</span>
          </Text>
          <Text style={{ display: 'flex', justifyContent: 'center', padding: '3px' }} data-cy="totalPayed">
            Déjà payé :<span style={{ fontWeight: 'bold', marginLeft: '5px' }}>{toEuro(totalPayed)}</span>
          </Text>
        </div>

        <Text margin="20px 0" textAlign="center" style={{ display: 'inline' }}>
          <span style={{ fontWeight: 'bold' }}>Attention ! </span>
          La commande ne sera validée qu&apos;une fois le coût total payé.
        </Text>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonLink to={`/bill/share/payment-choose/${orderId}`} style={{ textAlign: 'center', fontWeight: 'bold' }}  data-cy="btn-paid-other-one">
            Payer une autre part
          </ButtonLink>
        </div>
      </div>
      <Footer type="button" title="Revenir à la carte" onClick={() => history.push('/')} />
    </IonContent>
  );
}
