import styled from 'styled-components';

export const DARK = '#222428';
export const WHITE = 'white';

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-direction: column;
  background-color: ${props => (props.darkTheme ? DARK : WHITE)};
`;
export const FlexContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex};
  padding-top: ${props => props.paddingTop};
`;
export const SuccessTips = styled.div`
  position: absolute;
  height: 130%;
  width: 100%;
  background: ${props => (props.darkTheme ? DARK : WHITE)};
  z-index: 15;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export const SubContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 85%;
  border-radius: 3px;
  border: solid 2px ${props => (props.darkTheme ? WHITE : DARK)};
  padding: 0 20px;
`;
export const Imagecontainer = styled.div`
  position: absolute;
  top: -9vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const LottieContainer = styled.div`
  height: 35vh;
  width: 20vh;
  padding-top: 8vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => (props.darkTheme ? WHITE : DARK)};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeigth};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Time = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  color: ${DARK};
  background-color: #5bfcc2;
  border-radius: 50px;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.5em;
  margin-bottom: 1em;
`;
