import React, { useContext } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { colors } from 'kards-components';
import { ThemeContext } from 'src/modules/card/context/theme';
import { ReactComponent as Mail } from 'src/svg/mail.svg';

const BoxWrapper = styled.div`
  background: ${(props) => props.background};
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex:1;
  :focus {
    outline-width: 0;
  }
`;

const MailSvg = styled(Mail)`
  width: 4em;
  margin: 1.5em;
  .st0 {
    fill: ${(props) => props.color} !important;
  }
`;

const WarningWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; 
  text-align: center;
  margin: 20vh 2em 13vh;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: ${(props) => props.borderColor};
`;

const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => (props.color)};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-top: ${(props) => props.paddingTop};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
`;


const Contacts = () => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <BoxWrapper
      background={colors[get(theme, 'color.background')]}
    >
      <MailSvg color={colors[get(theme, 'color.title')]} />
      <Text color={colors[get(theme, 'color.title')]} fontSize="1em" textAlign="center" width="80%" fontWeight="300" paddingTop="1vw">
        Pour nous contacter, rien de plus simple, envoyez nous un mail à l’adresse :
      </Text>
      <Text color={colors[get(theme, 'color.title')]} fontSize="1.8em" fontWeight="400" paddingTop="1vw">
        <a href="mailto:support@kards.fr">
            support@kards.fr
        </a>
      </Text>
      <WarningWrapper borderColor={colors[get(theme, 'color.title')]}>
        <Text color={colors[get(theme, 'color.title')]} fontSize="1em" fontWeight="bold" paddingTop=".5em">
          Attention !
        </Text>
        <Text color={colors[get(theme, 'color.title')]} fontSize=".9em" paddingTop=".1em" paddingBottom="1em">
          Pour que nous puissions traiter au mieux votre demande en cas de problème,
          n’oubliez pas de nous renseigner le numéro de téléphone avec lequel vous avez commandé.
        </Text>
      </WarningWrapper>
    </BoxWrapper>
  );
};


export default Contacts;
