import React from 'react';
import { CategoriesGallery } from 'kards-components';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';

const GalleryList = ({ components }) => {
  const { cardData, setCurrentCategory, currentCategory }: any = useReadContext();
  const theme = useTheme();

  return (
    <CategoriesGallery
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
      components={components}
    />
  );
};

export default GalleryList;
