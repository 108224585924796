import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { Popular } from '../types';
import { useBasketContext } from 'src/modules/basket';

async function getMostPopular() {
  const doc = await firebase
    .app()
    .firestore()
    .collection('statProducts')
    .doc('mostPopular')
    .get();
  return doc.data() as Popular;
}

export function useMostPopularProduct() {
  const [popular, setPopular] = useState<Popular>({} as Popular);
  const { getProduct } = useBasketContext();

  useEffect(() => {
    getMostPopular().then(setPopular);
  }, []);

  const productMostPopular = (popular?.week || [])
    .map(p => ({ ...p, ...getProduct(p.id) })) // reconcilation with product in card
    .filter(p => p?.id && p?.price) // remove product is not in card
    .slice(0, 3); // take 3;

  return productMostPopular;
}
