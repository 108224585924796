import React from 'react';
import { Text } from 'src/common-ui';

export function Chip({ children, ...props }: any) {
  return (
    <div
      style={{
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        padding: '4px 20px',
        borderRadius: '30px',
      }}
    >
      <Text fontWeigth="800" fontSize="22px" textAlign="center" {...props}>
        {children}
      </Text>
    </div>
  );
}
