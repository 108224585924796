import React, { useContext } from 'react';
import { Video } from 'kards-components';

import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ component }) => {
  const { title, description, video } = component.value
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <Video
      theme={theme}
      title={title}
      description={description}
      video={video}
    />
  );
};
