import { Carousel } from 'kards-components';
import React, { useCallback, useRef } from 'react';
import useMedia from 'use-media';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { debounce } from 'lodash';
import { useAnalyticsContext } from 'src/analytics/AnalyticsContext';
import { eventNames } from 'src/analytics/eventNames';

export default ({ component }) => {
  const { id, products, title, description } = component.value
  const theme = useTheme();
  const isWide = useMedia({ minWidth: '500px' });
  const { logEvent, trackAddProductToBasket } = useAnalyticsContext()
  const stop = useCallback<({ baseWidth, seenWidth }: { baseWidth: Number, seenWidth: Number }) => void>(debounce(({ baseWidth, seenWidth }) => {
    //track
    logEvent({
      type: eventNames.carouselScrollStop,
      componentId: component.id,
      baseWidth,
      seenWidth,
    })
  }, 500), [])

  const onScroll = e => {
    const {scrollLeft, scrollWidth, offsetWidth} = e.target
    stop({
      baseWidth: offsetWidth / scrollWidth * 100,
      seenWidth: (offsetWidth + scrollLeft) / scrollWidth * 100,
    })
  }

  // analytics
  const from = useRef({ component }).current
  return (
    <Carousel key={id} 
      onScroll={onScroll}
      linkState={from}
      read theme={theme} data={{ products, title, description }} width={isWide ? '20vw' : '70vw'}>
      <AddRemoveBasket onAddProduct={trackAddProductToBasket({ component })} />
    </Carousel>
  );
};
