import React from 'react';
import { IonModal } from '@ionic/react';
import { useTheme } from 'src/modules/card/context/theme';
import styled from 'styled-components';
import get from 'lodash/get';
import { colors } from 'kards-components';
import { ViewProduct } from 'src/modules/product';

const IonModalWrapper = styled(IonModal)`
  align-items: flex-end;
  --background: ${props => props.background};
  height: 100%;
`;

export const ModalProduct = ({ open, setOpen, onChange }) => {
  const theme = useTheme();

  return (
    <IonModalWrapper
      isOpen={get(open, 'id') !== undefined}
      swipeToClose
      showBackdrop
      background={colors[get(theme, 'color.background')]}
      onDidDismiss={() => setOpen(false)}
    >
      <ViewProduct
        quantity={null}
        product={open}
        onSubmit={values => {
          onChange(values);
          setOpen(false);
        }}
      />
    </IonModalWrapper>
  );
};
